import { useState, useEffect } from "react";

//Internal

import { useGetFilterableAnswers } from "api/resources/projects/answers";
import styles from "./QuestionChart.module.scss";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Loading } from "components/Loading/Loading";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import Table from "./Tables/Table";
import Chart from "components/Charts/Chart";
import { CSVLink } from "react-csv";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchColorPaletteById } from "api/resources/organization/colorpalettes";
import {
  getColorsFromGradient,
  getColorsFromPalette,
  getComparisonColors,
} from "assets/functions/ColorFunctions";
import { combinedProjIds, combinedQs } from "./Visualization";
import { splitting } from "./Settings/SettingsAccordion";
import { trimDate, trimTimeDay } from "assets/functions/DateFunctions";

export const defaultColors = [
  "#616565",
  "#15bcc7",
  "#7fcfd3",
  "#b5e1df",
  "#ed9146",
  "#edb57e",
  "#f4e3c2",
  "#a4c6d0",
  "#8dabb2",
  "#738c91",
];

const fakeData = {
  labels: ["", "", ""],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      // label: "Amount",
      data: [125, 55, 82],
      // you can set indiviual colors for each bar
      // backgroundColor: ["#edb57e", "#FF8878", "#C1E08D"],
      backgroundColor: ["#15bcc7", "#dbdbdb", "#ed9146"],
      borderWidth: 0,
    },
  ],
};

/**
 * Does not take into account comparisons - for colors
 * @param {*} viz
 * @returns
 */
export function splitSomehow(viz) {
  return (
    viz.designSettings.split ||
    (viz.designSettings.asRanking && !viz.designSettings.scaleByRank) ||
    (combinedQs(viz)[0].matrixQuestion && !viz.designSettings.option)
  );

  // !viz.designSettings.split &&       not split
  // (!viz.designSettings.asRanking ||
  //   viz.designSettings.scaleByRank) &&
  // (!vizQs[0].matrixQuestion ||
  //   viz.designSettings.option)
}

export const VertBarType = "verticalbarchart";
export const HorizBarType = "horizontalbarchart";
export const DoughnutType = "doughnutchart";
export const PieType = "piechart";
export const LineType = "linechart";
export const ValueType = "value";
export const TableType = "table";
export const MetricType = "metric";

export const High2Low = "hightolow";
export const Low2High = "lowtohigh";
export const NoSort = "none";
export const ReverseSort = "reverse";
export const AtoZ = "AtoZ"; // 1-10
export const ZtoA = "ZtoA"; // 10-1

export const NpsScore = "nps score";
export const AnswerCount = "answer count";
export const AvgScore = "avg score";
export const ParticipationCount = "participation count";
export const Flywheel = "flywheel";
export const Ranking = "ranking";

export const defaultChartSettings = {
  hasDataLabels: true,
  dataLabelValue: {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  },
  dataLabelLabel: {
    show: false,
  },
  dataLabelPercent: {
    show: false,
  },
  hasTitle: true,
  hasSubtitle: true,
  artificialTitle: true,
  titleSize: null,
  titleAlignment: "center",
  titleBackgroundColor: "#ffffff",
  titleColor: "",
  titleBorderRadius: 0,
  hasLegend: false,
  hasLegendTitle: false,
  legendTitle: "",
  legendPosition: "top",
  legendFontSize: 14,
  legendLabel: true,
  legendValue: false,
  legendPercent: false,
  drawXLines: true,
  drawYLines: true,
  colors: undefined,
  paletteId: "default",
  gradient: undefined,
  lineWidth: 1,
  pointRadius: 3,
  lineGraphWidth: 3,
  lineGraphColor: "#2A627C",
  uniformPointColor: "#2A627C",
  hasUniformPointColor: true,
  sortData: "none",
  barPercentage: 0.9,
  cutout: 50,
  byPercent: true,
  byPercentOfTotal: false,
  answerType: "answer count",
  stacked: false,
  hasAxisTitles: true,
  autoGenAxisTitles: true,
  YAxisTitle: "Answer Count",
  XAxisTitle: "Choices",
  axisTitleFontSize: 12,
  split: "",
  lineTension: 0.3,
  graphMax: null,
  graphMin: null,
  undefinedLabel: "Undefined",
  zoomOut: 0,
  segLabelReplacements: {},
  axisLabelReplacements: {},
  textboxes: [],
  showUndefined: true,
  showN: false,
  NLabel: "N",
  useTagColor: false,
  showNonParticipating: false,
  afterSort: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  showFilterSubtitle: true,
  sigFigs: 0,
  hideXticks: false,
  hideYticks: false,
  hideTicks: true,
  hideBorder: false,
  containerShadow: true,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  syncPadding: true,
  paddingTopTitle: 0,
  paddingBottomTitle: 0,
  paddingLeftTitle: 0,
  paddingRightTitle: 0,
  syncTitlePadding: true,
  includeComment: true,
};

// Possible added on later include:
// asRanking
// scaleByRank
// option
// flip
// matrixScaleByRank

/**
 *
 * @param {*} noExpand For context of: viz belongs to a dash widget, they hit expand, can't expand normal way, don't want to expand again.
 * @returns
 */

export default function QuestionChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  newChart,
  setAbove,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  inEdit,
}) {
  const [data, setData] = useState(null);
  const vizQs = combinedQs(viz);
console.log(viz)
  const fetchAnswers = useGetFilterableAnswers(
    vizQs.map((q) => q.id),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    setData,
    ""
  );

  const comparableAnswers = useGetFilterableAnswers(
    viz.comparisonQs ? viz.comparisonQs.map((q) => q.id) : [],
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    setData,
    ""
  );

  const vizProjIds = combinedProjIds(viz);

  const getProjects = useFetchProjectsByIdsGql({
    projectIds: vizProjIds,
  });

  const getComparableProjects = useFetchProjectsByIdsGql({
    projectIds: viz.comparisonQs
      ? viz.comparisonQs.map((q) => q.projectId)
      : [],
  });

  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  const colorPalette = useFetchColorPaletteById(
    viz.designSettings.paletteId ? viz.designSettings.paletteId : ""
  );

  const updateViz = useUpdateVisualization();

  const [drill, setDrill] = useState();
  const [showDrill, setShowDrill] = useState(false);
  const [N, setN] = useState(0);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    setData(null);
  }, [viz]);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (setOutsideData && data) {
        setOutsideData({ ...data });
      }
    }
  }, [setUpOutsideDataCounter]);

  const getFilteredContactFields = (contactField) => {
    let active = [];

    if (filters) {
      let chosenFilter = JSON.parse(filters);
      for (let key in chosenFilter) {
        if (chosenFilter[key].name === contactField) {
          for (let value of chosenFilter[key].properties) {
            active.push(value);
          }
        }
      }

      if (active.length > 0) {
        return active;
      }
    }
    let field = custom_fields.find((f) => f.name === contactField);
    if (!field || !field?.properties) {
      return [];
    }
    return [...field.properties];
  };

  function comparing() {
    return (
      viz.designSettings.useComparison &&
      viz.comparisonQs &&
      viz.comparisonQs.length
    );
  }

  function getUndefinedLabel() {
    return viz.designSettings.undefinedLabel
      ? viz.designSettings.undefinedLabel
      : "Undefined";
  }

  function sortData(labels, answerTallies, colors) {
    let labelTallyMap = [];
    forEach(labels, (label, ind) =>
      labelTallyMap.push({
        label: label,
        tally: answerTallies[ind],
        color: colors[ind],
        orig: ind,
        sorted: ind,
      })
    );

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      forEach(labelTallyMap, (l) => {
        if (l.orig in viz.designSettings?.segLabelReplacements) {
          l.label = viz.designSettings.segLabelReplacements[l.orig];
          l.origLabel = labels[l.orig]; // So you can know what it was originally
          labels[l.orig] = l.label;
        }
      });
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        labelTallyMap.reverse();
      } else {
        labelTallyMap.sort((a, b) => {
          if (viz.designSettings.sortData === High2Low) {
            return b.tally - a.tally;
          }
          if (viz.designSettings.sortData === Low2High) {
            return a.tally - b.tally;
          }

          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return a.label - b.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return b.label - a.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
        });
      }

      forEach(labelTallyMap, (choice, ind) => {
        labels[ind] = choice.label;
        answerTallies[ind] = choice.tally;

        // if (viz.designSettings?.afterSort) {
        //   choice.color = colors[ind];
        // } else {
        //   colors[ind] = choice.color;
        // }
        if (viz.designSettings.colors && !viz.designSettings?.afterSort) {
          colors[ind] = choice.color;
        } else {
          choice.color = colors[ind];
        }

        choice.sorted = ind; // For Chart Settings
      });
    }

    if (setLabelInfo) {
      let labelInfo = {
        segLabels: labelTallyMap,
        axisLabels: labelTallyMap,
      };
      setLabelInfo(labelInfo);
    }
  }

  function changeToPercentData(answerTallies) {
    if (viz.designSettings.byPercentOfTotal) {
      let sum = 0;
      forEach(answerTallies, (tally) => (sum += tally));
      for (let i = 0; i < answerTallies.length; i++) {
        answerTallies[i] = ((answerTallies[i] * 100) / sum).toFixed(
          viz.designSettings.dataLabelValue.sigFigs
        );
      }
    } else {
      let partCount = getAnswers().length;
      if (partCount) {
        for (let i = 0; i < answerTallies.length; i++) {
          let portion = (answerTallies[i] / partCount) * 100;
          answerTallies[i] = portion.toFixed(
            viz.designSettings.dataLabelValue.sigFigs
          );
        }
      }
    }
  }

  function getDefaultColors(length) {
    let colors = [];
    let c = 0;
    for (let i = 0; i < length; i++) {
      colors[i] = defaultColors[c];
      c < defaultColors.length - 1 ? c++ : (c = 0);
    }
    return colors;
  }

  function getColors(num, data) {
    let compSimple = false;
    const palette = colorPalette.data.palette;
    let colors = [];
    if (viz.designSettings.colors) {
      let c = 0;
      let copy = viz.designSettings.colors;
      for (let i = 0; i < num; i++) {
        colors[i] = copy[c];
        c < copy.length - 1 ? c++ : (c = 0);
      }
    } else if (viz.designSettings.gradient) {
      let gradient = viz.designSettings.gradient;
      colors = getColorsFromGradient(gradient.anchors, gradient.blended, num);
    } else if (palette) {
      if (comparing() && !(num % 2)) {
        if (splitSomehow(viz)) {
          let half = num / 2;
          let firstSet = getColorsFromPalette(palette, half);
          colors = getComparisonColors(firstSet);
        } else {
          // compSimple = true;
          // let actual = data.datasets[0].data.length;
          // let origSet = getColorsFromPalette(palette, actual);
          // colors = getComparisonColors(origSet);
          colors = getColorsFromPalette(palette, num);
        }
      } else {
        colors = getColorsFromPalette(palette, num);
      }
    } else {
      colors = getDefaultColors(num);
    }
    return [colors, compSimple];
  }

  function compileData(labels, answerTallies) {
    let data = JSON.parse(JSON.stringify(fakeData));

    let [colors] = getColors(labels.length);

    //Survey Tag Colors Display
    if (viz.pivotString === "survey tag" && viz.designSettings?.useTagColor) {
      for (let i = 0; i < labels.length; i++) {
        let tag = getSurveyTags.data.tags.find((t) => t.label === labels[i]);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    sortData(labels, answerTallies, colors);

    if (
      viz.designSettings.byPercent &&
      !(
        viz.designSettings.answerType === AvgScore ||
        viz.designSettings.answerType === NpsScore ||
        viz.designSettings.answerType === Flywheel
      )
    ) {
      changeToPercentData(answerTallies);
    }

    forEach(labels, (label, i) => {
      if (label === "Undefined") {
        labels[i] = getUndefinedLabel();
      }
    });

    data.datasets[0].backgroundColor = colors;
    data.datasets[0].data = answerTallies;
    data.datasets[0].borderRadius = viz.designSettings.borderRadius
      ? viz.designSettings.borderRadius
      : 0;
    data.datasets[0].borderSkipped = viz.designSettings.borderSkipped;
    data.labels = labels;

    return data;
  }

  function sortSplitData(data) {
    let segLabels = [];
    forEach(data.datasets, (dataset, i) =>
      segLabels.push({
        label: dataset.label,
        orig: i,
        sorted: i,
        color: dataset.backgroundColor[0],
      })
    );

    // add origLabel
    for (let l of segLabels) {
      if (
        viz.designSettings?.segLabelReplacements &&
        l.orig in viz.designSettings?.segLabelReplacements
      ) {
        l.label = viz.designSettings.segLabelReplacements[l.orig];
        l.origLabel = data.datasets[l.orig].label; // So you can know what it was originally
        data.datasets[l.orig].label = l.label; // Actually change it
      }
    }

    let axisLabels = [];
    if (data.labels) {
      forEach(data.labels, (label, i) =>
        axisLabels.push({
          label: label,
          orig: i,
          sorted: i,
        })
      );

      for (let l of axisLabels) {
        if (
          viz.designSettings?.axisLabelReplacements &&
          l.orig in viz.designSettings?.axisLabelReplacements
        ) {
          l.label = viz.designSettings.axisLabelReplacements[l.orig];
          l.origLabel = data.labels[l.orig]; // So you can know what it was originally
          data.labels[l.orig] = l.label; // Actually change it
        }
      }
    }

    if (setLabelInfo) {
      let labelsInfo = {
        segLabels: segLabels,
        axisLabels: axisLabels,
      };
      setLabelInfo(labelsInfo);
    }

    let labelMap = {};
    for (let i = 0; i < data.labels.length; i++) {
      labelMap[data.labels[i]] = i;
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        data.labels.reverse();
      } else {
        data.labels.sort((a, b) => {
          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a === "number" && typeof b === "number") {
              return a - b;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a === "number" && typeof b === "number") {
              return b - a;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }

      // axisLabels.forEach((one, i) => (one.sorted = i));

      let order = data.labels.map((label) => labelMap[label]);

      for (let set of data.datasets) {
        let copy = [];
        forEach(order, (num, ind) => {
          copy[ind] = set.data[num];
        });
        set.data = copy;
      }
      // data.labels = axisLabels.map(l => l.label);
    }
  }

  function changeToPercentOnSplitData(data, axisLabels) {
    // if (viz.designSettings.byPercentOfTotal) {
    //   for (let dataset of data.datasets) {
    //     let sum = 0;
    //     let tallyArray = dataset.data;
    //     forEach(tallyArray, (tally) => (sum += tally));
    //     for (let i = 0; i < tallyArray.length; i++) {
    //       tallyArray[i] = ((tallyArray[i] * 100) / sum).toFixed(
    //         viz.designSettings.dataLabelValue.sigFigs
    //       );
    //     }
    //   }
    // }

    for (let i = 0; i < axisLabels.length; i++) {
      let partCount = 0;
      for (let dataset of data.datasets) {
        partCount += dataset.data[i];
      }

      if (!partCount) {
        continue;
      }
      
      for (let dataset of data.datasets) {
        let portion = (dataset.data[i] / partCount) * 100;
        dataset.data[i] = portion.toFixed(
          viz.designSettings.dataLabelValue.sigFigs
        );
      }
    }
  }

  function bindColorsOnSplitData(data) {
    let [colors, compSimple] = getColors(data.datasets.length, data);

    //Survey Tag Colors Display
    if (
      viz.designSettings.split === "survey tag" &&
      viz.designSettings?.useTagColor
    ) {
      for (let i = 0; i < data.datasets.length; i++) {
        let tag = getSurveyTags.data.tags.find(
          (t) => t.label === data.datasets[i].label
        );
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    if (compSimple) {
      let normal = [];
      let comp = [];
      for (let i = 0; i < colors.length; i += 2) {
        normal.push(colors[i]);
        comp.push(colors[i + 1]);
      }
      data.datasets[0].backgroundColor = normal;
      data.datasets[1].backgroundColor = comp;
    } else {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].backgroundColor = [colors[i]];
        // data.datasets[i].borderWidth = 0;
      }
    }
  }

  function bindColorsAndSortData(labels, datasets) {
    let data = {
      labels: labels,
      datasets: datasets,
      stacked: viz.designSettings.stacked,
    };

    bindColorsOnSplitData(data);
    sortSplitData(data);

    if (
      viz.designSettings.byPercent &&
      !(
        viz.designSettings.answerType === AvgScore ||
        (viz.designSettings.answerType === NpsScore &&
          viz.designSettings.split !== "nps")
      )
    ) {
      changeToPercentOnSplitData(data, labels);
    }
    return data;
  }

  function compileSplitData(legendLabels, answerMap, axisLabels) {
    let datasets = [];

    if (viz.designSettings.includeOverall) {
      let spot = legendLabels.indexOf("Overall");
      legendLabels.splice(spot, 1);
      legendLabels.push("Overall");

      if (comparing()) {
        let compLabel = comparisonLabel();
        spot = legendLabels.indexOf(compLabel + " | Overall");
        legendLabels.splice(spot, 1);
        legendLabels.push(compLabel + " | Overall");
      }
    }

    const byPartOrPercent =
      viz.designSettings.answerType === ParticipationCount ||
      viz.designSettings.byPercent;
    const nps = viz.designSettings.answerType === NpsScore;

    for (let i = 0; i < legendLabels.length; i++) {
      let seg = legendLabels[i];
      let data = [];

      for (let label of axisLabels) {
        if (answerMap[seg] && answerMap[seg][label]) {
          data.push(answerMap[seg][label]);
        } else if (nps && seg in answerMap && label in answerMap[seg]) {
          data.push(answerMap[seg][label]);
        } else {
          byPartOrPercent ? data.push(0) : data.push(null);
        }
      }

      if (seg === "Undefined") {
        seg = getUndefinedLabel();
      } else if (comparing() && seg === comparisonLabel() + " | Undefined") {
        seg = comparisonLabel() + " | " + getUndefinedLabel();
      }

      if (
        viz.designSettings.includeOverall &&
        viz.designSettings.overallLabel
      ) {
        if (seg === "Overall") {
          seg = viz.designSettings.overallLabel;
        } else if (comparing()) {
          let compLabel = comparisonLabel();
          if (seg === compLabel + " | Overall") {
            seg = compLabel + " | " + viz.designSettings.overallLabel;
          }
        }
      }

      datasets.push({
        label: seg,
        data: data,
      });
    }

    if (viz.designSettings.showUndefined) {
      let ind = axisLabels.indexOf("Undefined");
      if (ind > -1) {
        axisLabels[ind] = getUndefinedLabel();
      }
    }

    let data = bindColorsAndSortData(axisLabels, datasets);
    return data;
  }

  function comparisonLabel() {
    return viz.designSettings.comparisonLabel
      ? viz.designSettings.comparisonLabel
      : "Comparison";
  }

  function compileComparedData(answerMap, compMap, axisLabels) {
    let chosen = viz.designSettings.chosenLabel
      ? viz.designSettings.chosenLabel
      : "Chosen";
    let comp = comparisonLabel();
    let combinedMap = {};
    combinedMap[chosen] = answerMap;
    combinedMap[comp] = compMap;
    return compileSplitData([chosen, comp], combinedMap, axisLabels);
  }

  function compileSplitComparedData(
    legendLabels,
    answerMap,
    axisLabels,
    compMap
  ) {
    let comparedLabel = comparisonLabel();
    let combinedMap = { ...answerMap };
    let combinedLabels = [];
    for (let label of legendLabels) {
      combinedLabels.push(label);
      let newLabel = comparedLabel + " | " + label;
      combinedLabels.push(newLabel);
      combinedMap[newLabel] = compMap[label];
    }

    return compileSplitData(combinedLabels, combinedMap, axisLabels);
  }

  function findAssociatedTag(answer) {
    for (let tag of getSurveyTags.data.tags) {
      let found = tag.project.some(
        (p) => p.id === answer.participation.projectId
      );
      if (found) {
        return tag.label;
      }
    }
    return "Undefined";
  }

  function settleCustomFields(contact) {
    if (contact.customField) {
      let customFields = JSON.parse(contact.customField);
      while (typeof customFields === "string") {
        customFields = JSON.parse(customFields);
      }
      for (let cField in customFields) {
        contact[cField] = customFields[cField];
      }
      delete contact.customFields;
    }
  }

  function getProjectsInOrder() {
    let projects = [...getProjects.data?.survey];

    if (comparing()) {
      projects = [...projects, ...getComparableProjects.data?.survey];
    }

    projects.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      let results = aDate - bDate;
      return results;
    });
    return projects;
  }

  function getSurveyLabelFromAnswer(val, answer) {
    if (val === "survey tag") {
      return findAssociatedTag(answer);
    }
    let projID = answer.participation?.projectId;
    let proj = getProjects.data.survey.find((s) => s.id === projID);
    if (!proj && comparing()) {
      proj = getComparableProjects.data?.survey.find((s) => s.id === projID);
    }
    return getSurveyLabel(val, proj);
  }

  function getAnswerBucketLabels(result) {
    if (Array.isArray(result)) {
      return result;
    } else {
      if (viz.designSettings?.includeAll) {
        let keys = [];
        for (let b in result.breakdown) {
          if (result.breakdown[b].length) {
            keys.push(b);
          }
        }
        return keys;
      }

      return [result.overall];
    }
  }

  // passes a pivotBySurvey for speed, to avoid that calculation every time
  function getPivotLabels(pivotBySurvey, answer) {
    let label = "";
    let isField = false;
    if (pivotBySurvey) {
      label = getSurveyLabelFromAnswer(viz.pivotString, answer);
    } else if (viz.pivotString === "month taken") {
      label = getMonthTaken(answer);
    }else if (viz.pivotString === "hour taken") {
      label = getHourTaken(answer);
    } else {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.pivotString];
        isField = true;
      }
    }

    if (!label && answer.buckets) {
      let answerBuckets = JSON.parse(answer.buckets);
      if (answerBuckets[viz.pivotString]) {
        return getAnswerBucketLabels(answerBuckets[viz.pivotString]);
      }
    }

    if (!label) {
      label = "Undefined";
    }

    if (isField) {
      let delimiter = custom_fields.find(
        (field) => field.name === viz.pivotString
      )?.delimiter;
      if (delimiter) {
        label = label.split(delimiter);
      } else {
        label = [label];
      }
    } else {
      label = [label];
    }

    return label;
  }

  // passes a splitBySurvey for speed, to avoid that calculation every time
  function getSplitLabels(splitBySurvey, answer) {
    let label = "";
    let isField = false;
    if (splitBySurvey) {
      label = getSurveyLabelFromAnswer(viz.designSettings.split, answer);
    } else if (viz.designSettings.split === "month taken") {
      label = getMonthTaken(answer);
    } else {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.designSettings.split];
        isField = true;
      }
    }

    if (!label && answer.buckets) {
      let answerBuckets = JSON.parse(answer.buckets);
      if (answerBuckets[viz.designSettings.split]) {
        return getAnswerBucketLabels(answerBuckets[viz.designSettings.split]);
      }
    }

    if (!label) {
      label = "Undefined";
    }

    if (isField) {
      let delimiter = custom_fields.find(
        (field) => field.name === viz.designSettings.split
      )?.delimiter;
      if (delimiter) {
        label = label.split(delimiter);
      } else {
        label = [label];
      }
    } else {
      label = [label];
    }

    if (viz.designSettings.includeOverall) {
      label.push("Overall");
    }

    return label;
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "December",
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function getMonthTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);

    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + year;
  }

  function getHourTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);
    date.setMinutes(0, 0, 0);
    return trimTimeDay(date, true);
  }

  function getMonths() {
    let map = {};
    for (let answer of fetchAnswers.data.answers) {
      let label = getMonthTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }

  function getHours() {
    let map = {};
    for (let answer of fetchAnswers.data.answers) {
      let label = getHourTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }

  function findTagFromSurvey(proj) {
    for (let tag of getSurveyTags.data.tags) {
      let found = tag.project.some((p) => p.id === proj.id);
      if (found) {
        return tag.label;
      }
    }
    return "Undefined";
  }

  function getSurveyLabel(val, survey) {
    if (!survey) {
      return "Undefined";
    }
    if (val === "survey") {
      return survey.name;
    }
    if (val === "survey tag") {
      return findTagFromSurvey(survey);
    }
    if (val === "survey date" || val === "survey quarter") {
      let label = survey.name;
      if (survey.startedAt) {
        let date = new Date(survey.startedAt);
        let month = date.toDateString().substring(4, 7);
        let year = date.toDateString().substring(11, 15);
        label = month + " " + year;
        if (val === "survey quarter") {
          if (month === "Jan" || month === "Feb" || month === "Mar") {
            label = "Q1 " + year;
          } else if (month === "Apr" || month === "May" || month === "Jun") {
            label = "Q2 " + year;
          } else if (month === "Jul" || month === "Aug" || month === "Sep") {
            label = "Q3 " + year;
          } else if (month === "Oct" || month === "Nov" || month === "Dec") {
            label = "Q4 " + year;
          }
        }
      }
      return label;
    }
  }

  function getLabelsFor(val) {
    let labels = [];

    if (val.includes("survey")) {
      let onlyTheseSurveyIds = [];
      if (filters) {
        let chosenFilter = JSON.parse(filters);
        if (chosenFilter.surveys) {
          onlyTheseSurveyIds = chosenFilter.surveys.map((s) => s.id);
        }
      }

      let projects = getProjectsInOrder();
      for (let survey of projects) {
        if (
          onlyTheseSurveyIds.length &&
          !onlyTheseSurveyIds.includes(survey.id)
        ) {
          continue;
        }

        let label = getSurveyLabel(val, survey);
        if (!labels.includes(label)) {
          labels.push(label);
        }
      }
    } else if (val === "month taken") {
      labels = getMonths();
    } else if (val === "hour taken") {
      labels = getHours();
    }else {
      labels = getFilteredContactFields(val);
    }

    if (!labels.length) {
      // check buckets
      for (let question of vizQs) {
        if (question.textQuestion && question.textQuestion.bucket) {
          for (let bucket of question.textQuestion.bucket) {
            if (bucket.name === val) {
              for (let prop of bucket.properties) {
                if (!labels.includes(prop)) {
                  labels.push(prop);
                }
              }
            }
          }
        }
      }
    }

    if (val === viz.designSettings.split && viz.designSettings.includeOverall) {
      labels.push("Overall");
    }

    return labels;
  }

  function pivotFlywheel() {
    // No comparison cuz all you can pivot is the survey, and that won't really work for comparison
    let answerMap = {};

    let questionsInSurvey = {};
    for (let projId of vizProjIds) {
      questionsInSurvey[projId] = {};
      for (let q of vizQs) {
        if (q.projectId === projId) {
          questionsInSurvey[projId][q.id] = 0;
        }
      }
    }

    for (let answer of getAnswers()) {
      if (!answer.participation || answer.scaleAnswer == null) {
        continue;
      }
      let partId = answer.participation.id;
      let projId = answer.participation.projectId;
      if (!answerMap[partId]) {
        answerMap[partId] = {
          questions: { ...questionsInSurvey[projId] },
          projectId: projId,
        };
      }
      answerMap[partId].questions[answer.questionId] = answer.scaleAnswer;
    }

    let labels = getLabelsFor(viz.pivotString);

    let labelInd = {};
    let flywheelMap = {};

    for (let survey of getProjects.data?.survey) {
      let label = getSurveyLabel(viz.pivotString, survey);
      let index = labels.indexOf(label);
      labelInd[survey.id] = index;

      flywheelMap[survey.id] = {
        total: 0,
        count: 0,
      };
    }

    for (let participation in answerMap) {
      let projId = answerMap[participation].projectId;
      let answers = answerMap[participation].questions;
      for (let question in answers) {
        if (answers[question]) {
          flywheelMap[projId].total += answers[question];
        }
      }
      flywheelMap[projId].count++;
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(0);
    }

    for (let surveyId in flywheelMap) {
      if (flywheelMap[surveyId].count) {
        let avg = flywheelMap[surveyId].total / flywheelMap[surveyId].count;
        if (answerTallies[labelInd[surveyId]]) {
          // Two surveys in the same Quarter or Month...
          let runningAvg = parseFloat(answerTallies[labelInd[surveyId]]);
          avg = (runningAvg + avg) / 2;
        }
        avg = avg.toFixed(
          viz.designSettings?.sigFigs ? viz.designSettings?.sigFigs : 0
        );
        answerTallies[labelInd[surveyId]] = avg;
      }
    }

    let data = compileData(labels, answerTallies);
    return data;
  }

  function getFlywheelCompared() {
    let labels = [1, 2, 3, 4, 5, 6];
    let answerMap = {};
    forEach(labels, (number) => (answerMap[number] = 0));
    let compMap = { ...answerMap };

    for (let answer of getAnswers()) {
      if (answer.scaleAnswer in answerMap) {
        answerMap[answer.scaleAnswer] += 1;
      }
    }

    for (let answer of getComparableAnswers()) {
      if (answer.scaleAnswer in compMap) {
        compMap[answer.scaleAnswer] += 1;
      }
    }

    if (viz.comparisonQs.length > 2) {
      for (let num of labels) {
        let div = Math.floor(viz.comparisonQs.length / 2);
        compMap[num] = Math.round(compMap[num] / div);
      }
    }

    let data = compileComparedData(answerMap, compMap, labels);
    return data;
  }

  function getFlywheelData() {
    if (viz.pivotString) {
      return pivotFlywheel();
    }
    if (comparing()) {
      return getFlywheelCompared();
    }

    let labels = [1, 2, 3, 4, 5, 6];
    let answerMap = {};
    forEach(labels, (number) => (answerMap[number] = 0));

    for (let answer of getAnswers()) {
      if (answer.scaleAnswer in answerMap) {
        answerMap[answer.scaleAnswer] += 1;
      }
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(answerMap[label]);
    }
    let data = compileData(labels, answerTallies);

    return data;
  }

  function matrixRankingScaledComparing(options, choices, choicesMap, limit) {
    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(choices, (c) => {
      answerMap[c] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        let weight = limit;
        for (let choice of matrixAnswer[option]) {
          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            map[label][option] += weight;
          }
          weight--;
        }
      }
    }

    for (let answer of fetchAnswers.data?.answers) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of comparableAnswers.data?.answers) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let option of options) {
          if (answerMap[choice][option] || compMap[choice][option]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    for (let choice of choices) {
      for (let option of options) {
        compMap[choice][option] = Math.round(
          compMap[choice][option] / viz.comparisonQs.length
        );
      }
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let sets = [
        { length: getAnswers().length, map: answerMap },
        { length: getComparableAnswers().length, map: compMap },
      ];
      for (let set of sets) {
        let map = set.map;
        let length = set.length;
        for (let option of options) {
          for (let choice of choices) {
            if (map[choice][option]) {
              if (viz.designSettings.standardScale) {
                map[choice][option] = (map[choice][option] / length).toFixed(1);
              } else {
                let val = (map[choice][option] / length / limit) * 100;
                map[choice][option] = Math.round(val);
              }
            }
          }
        }
      }
    }

    if (viz.designSettings.flip) {
      [choices, answerMap, options] = getFlippedData(
        choices,
        answerMap,
        options
      );

      let extraLL, extraAL;
      [extraLL, compMap, extraAL] = getFlippedData([...options], compMap, [
        ...choices,
      ]);
    }

    let data = compileSplitComparedData(choices, answerMap, options, compMap);
    return data;
  }

  function matrixRankingScaled(options, choices, choicesMap) {
    let limit = 0;
    for (let q of vizQs) {
      if (q.choiceQuestion.limit && q.choiceQuestion.limit > limit) {
        limit = q.choiceQuestion.limit;
      } else {
        let length = q.choiceQuestion.choices.length;
        if (q.choiceQuestion.hasOtherOption) {
          length++;
        }
        if (length > limit) {
          limit = q.choiceQuestion.choices.length;
        }
      }
    }

    if (comparing()) {
      return matrixRankingScaledComparing(options, choices, choicesMap, limit);
    }

    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(choices, (c) => {
      answerMap[c] = { ...blankData };
    });

    for (let answer of fetchAnswers.data?.answers) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        let weight = limit;
        for (let choice of matrixAnswer[option]) {
          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            answerMap[label][option] += weight;
          }
          weight--;
        }
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let option of options) {
          if (answerMap[choice][option]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      for (let option of options) {
        let length = getAnswersLengthForOption(option);
        for (let choice of choices) {
          if (answerMap[choice][option]) {
            if (viz.designSettings.standardScale) {
              answerMap[choice][option] = (
                answerMap[choice][option] / length
              ).toFixed(1);
            } else {
              let val = (answerMap[choice][option] / length / limit) * 100;
              answerMap[choice][option] = Math.round(val);
            }
          }
        }
      }
    }

    if (viz.designSettings.flip) {
      [choices, answerMap, options] = getFlippedData(
        choices,
        answerMap,
        options
      );
    }

    let data = compileSplitData(choices, answerMap, options);
    return data;
  }

  function matrixChoiceCompared(options, choices, choicesMap) {
    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(choices, (c) => {
      answerMap[c] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        for (let choice of matrixAnswer[option]) {
          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            map[label][option] += 1;
          }
        }
      }
    }

    for (let answer of fetchAnswers.data?.answers) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of comparableAnswers?.data?.answers) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let option of options) {
          if (answerMap[choice][option] || compMap[choice][option]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    for (let choice of choices) {
      for (let option of options) {
        compMap[choice][option] = Math.round(
          compMap[choice][option] / viz.comparisonQs.length
        );
      }
    }

    if (viz.designSettings.flip) {
      [choices, answerMap, options] = getFlippedData(
        choices,
        answerMap,
        options
      );

      let extraLL, extraAL;
      [extraLL, compMap, extraAL] = getFlippedData([...options], compMap, [
        ...choices,
      ]);
    }

    let data = compileSplitComparedData(choices, answerMap, options, compMap);
    return data;
  }

  function matrixChoiceData(options) {
    let choices = [];
    let choicesMap = {};

    for (let q of vizQs) {
      if (q.choiceQuestion) {
        let choiceOptions = q.choiceQuestion.choices;
        for (let choice of choiceOptions) {
          let lowered = choice.toLowerCase();
          if (!(lowered in choicesMap)) {
            choicesMap[lowered] = choice;
            choices.push(choice);
          }
        }
      }
    }

    if (viz.designSettings.matrixScaleByRank) {
      return matrixRankingScaled(options, choices, choicesMap);
    }
    if (comparing()) {
      return matrixChoiceCompared(options, choices, choicesMap);
    }

    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(choices, (c) => {
      answerMap[c] = { ...blankData };
    });

    for (let answer of fetchAnswers.data?.answers) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        for (let choice of matrixAnswer[option]) {
          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            answerMap[label][option] += 1;
          }
        }
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let option of options) {
          if (answerMap[choice][option]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    if (viz.designSettings.flip) {
      [choices, answerMap, options] = getFlippedData(
        choices,
        answerMap,
        options
      );
    }

    let data = compileSplitData(choices, answerMap, options);
    return data;
  }

  function matrixScaleDataCompared(options, range) {
    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(range, (num) => {
      answerMap[num] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        map[matrixAnswer[option]][option] += 1;
      }
    }

    for (let answer of fetchAnswers.data?.answers) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of comparableAnswers.data?.answers) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let num of range) {
        for (let option of options) {
          if (answerMap[num][option] || compMap[num][option]) {
            keeping.push(num);
            break;
          }
        }
      }
      range = keeping;
    }

    for (let num of range) {
      for (let option of options) {
        compMap[num][option] = Math.round(
          compMap[num][option] / viz.comparisonQs.length
        );
      }
    }

    if (viz.designSettings.flip) {
      [range, answerMap, options] = getFlippedData(range, answerMap, options);

      let extraLL, extraAl;
      [extraLL, compMap, extraAl] = getFlippedData([...options], compMap, [
        ...range,
      ]);
    }

    let data = compileSplitComparedData(range, answerMap, options, compMap);
    return data;
  }

  function matrixScaleData(options) {
    let range = [];

    for (let q of vizQs) {
      for (
        let i = q.scaleQuestion.min;
        i <= q.scaleQuestion.max;
        i += q.scaleQuestion.step
      ) {
        if (!range.includes(i)) {
          range.push(i);
        }
      }
    }

    if (comparing()) {
      return matrixScaleDataCompared(options, range);
    }

    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let answerMap = {};
    forEach(range, (num) => {
      answerMap[num] = { ...blankData };
    });

    for (let answer of fetchAnswers.data?.answers) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        answerMap[matrixAnswer[option]][option] += 1;
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let num of range) {
        for (let option of options) {
          if (answerMap[num][option]) {
            keeping.push(num);
            break;
          }
        }
      }
      range = keeping;
    }

    if (viz.designSettings.flip) {
      [range, answerMap, options] = getFlippedData(range, answerMap, options);
    }

    let data = compileSplitData(range, answerMap, options);
    return data;
  }

  function matrixTextSplitCompared(options, val) {
    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let splitFields = getLabelsFor(val);
    let answerMap = {};
    forEach(splitFields, (s) => (answerMap[s] = { ...blankData }));
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const splitBySurvey = val.includes("survey");

    function putInAnswer(answer, map) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        let fields = viz.pivotString
          ? getPivotLabels(splitBySurvey, answer)
          : getSplitLabels(splitBySurvey, answer);

        for (let split of fields) {
          if (!map[split]) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              answerMap[split] = { ...blankData };
              compMap[split] = { ...blankData };
              splitFields.push(split);
            } else {
              continue;
            }
          }

          map[split][option] += 1;
        }
      }
    }

    for (let answer of fetchAnswers.data.answers) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of comparableAnswers.data?.answers) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split of splitFields) {
        for (let option of options) {
          if (answerMap[split][option] || compMap[split][option]) {
            keeping.push(split);
            break;
          }
        }
      }
      splitFields = keeping;
    }

    for (let split of splitFields) {
      for (let option of options) {
        compMap[split][option] = Math.round(
          compMap[split][option] / viz.comparisonQs.length
        );
      }
    }

    if (viz.pivotString) {
      [splitFields, answerMap, options] = getFlippedData(
        splitFields,
        answerMap,
        options
      );

      let extraLL, extraAL;
      [extraLL, compMap, extraAL] = getFlippedData([...options], compMap, [
        ...splitFields,
      ]);
    }

    let data = compileSplitComparedData(
      splitFields,
      answerMap,
      options,
      compMap
    );
    return data;
  }

  function matrixTextSplit(options, val) {
    if (comparing()) {
      return matrixTextSplitCompared(options, val);
    }
    let blankData = {};
    forEach(options, (o) => (blankData[o] = 0));

    let splitFields = getLabelsFor(val);
    let answerMap = {};
    forEach(splitFields, (s) => (answerMap[s] = { ...blankData }));

    const splitBySurvey = val.includes("survey");

    for (let answer of fetchAnswers.data.answers) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        let fields = viz.pivotString
          ? getPivotLabels(splitBySurvey, answer)
          : getSplitLabels(splitBySurvey, answer);

        for (let split of fields) {
          if (!answerMap[split]) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              answerMap[split] = { ...blankData };
              splitFields.push(split);
            } else {
              continue;
            }
          }

          answerMap[split][option] += 1;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split of splitFields) {
        for (let option of options) {
          if (answerMap[split][option]) {
            keeping.push(split);
            break;
          }
        }
      }
      splitFields = keeping;
    }

    if (viz.pivotString) {
      [splitFields, answerMap, options] = getFlippedData(
        splitFields,
        answerMap,
        options
      );
    }

    let data = compileSplitData(splitFields, answerMap, options);
    return data;
  }

  function matrixTextDataCompared(options) {
    let answerMap = {};
    forEach(options, (o) => (answerMap[o] = 0));
    let compMap = { ...answerMap };

    function putInAnswer(answer, map) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        map[option] += 1;
      }
    }

    for (let answer of fetchAnswers.data?.answers) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of comparableAnswers.data?.answers) {
      putInAnswer(answer, compMap);
    }

    for (let option of options) {
      compMap[option] = Math.round(compMap[option] / viz.comparisonQs.length);
    }

    let data = compileComparedData(answerMap, compMap, options);
    return data;
  }

  function matrixTextData(options) {
    viz.designSettings.YAxisTitle = "Response Count";

    if (viz.designSettings.split || viz.pivotString) {
      let val = viz.pivotString ? viz.pivotString : viz.designSettings.split;
      return matrixTextSplit(options, val);
    }

    if (comparing()) {
      return matrixTextDataCompared(options);
    }

    let answerMap = {};
    forEach(options, (o) => (answerMap[o] = 0));

    for (let answer of fetchAnswers.data?.answers) {
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        answerMap[option] += 1;
      }
    }

    let answerTallies = [];
    for (let option of options) {
      answerTallies.push(answerMap[option]);
    }

    let data = compileData(options, answerTallies);
    return data;
  }

  function getFlippedData(splits, answerMap, pivots) {
    let blankData = {};
    forEach(splits, (s) => (blankData[s] = 0));

    let flippedMap = {};
    forEach(pivots, (p) => {
      flippedMap[p] = { ...blankData };
    });

    for (let pivot of pivots) {
      for (let split of splits) {
        flippedMap[pivot][split] = answerMap[split][pivot];
      }
    }

    return [[...pivots], flippedMap, [...splits]];
  }

  function getMatrixData() {
    let options = [];
    for (let q of vizQs) {
      for (let option of q.matrixQuestion.options) {
        if (!options.includes(option)) {
          options.push(option);
        }
      }
    }

    if (vizQs[0].choiceQuestion) {
      return matrixChoiceData(options);
    }
    if (vizQs[0].scaleQuestion) {
      return matrixScaleData(options);
    }
    if (vizQs[0].textQuestion) {
      return matrixTextData(options);
    }
  }

  function getAnswers() {
    if (vizQs[0].matrixQuestion && viz.designSettings.option) {
      const option = viz.designSettings.option;

      const scale = vizQs[0].scaleQuestion ? true : false;
      const choice = vizQs[0].choiceQuestion ? true : false;
      const text = vizQs[0].textQuestion ? true : false;

      let array = [];
      for (let answer of fetchAnswers.data.answers) {
        if (answer.matrixAnswer) {
          let matrixAnswer = JSON.parse(answer.matrixAnswer);
          if (option in matrixAnswer) {
            if (scale) {
              answer.scaleAnswer = matrixAnswer[option];
            } else if (choice) {
              answer.choiceAnswer = matrixAnswer[option];
            } else if (text) {
              answer.textAnswer = matrixAnswer[option];
            }
            array.push(answer);
          }
        }
      }
      return array;
    }
    return fetchAnswers.data.answers;
  }

  function getAnswersLengthForOption(option) {
    let count = 0;
    // only included those who answered the option in the count
    for (let a of fetchAnswers.data.answers) {
      if (a.matrixAnswer) {
        let matrixAnswer = JSON.parse(a.matrixAnswer);
        if (option in matrixAnswer) {
          count++;
        }
      }
    }
    return count;
  }

  function getComparableAnswers() {
    if (viz.comparisonQs && comparableAnswers?.data?.answers) {
      if (vizQs[0].matrixQuestion && viz.designSettings.option) {
        const option = viz.designSettings.option;

        const scale = vizQs[0].scaleQuestion ? true : false;
        const choice = vizQs[0].choiceQuestion ? true : false;
        const text = vizQs[0].textQuestion ? true : false;

        let array = [];
        for (let answer of comparableAnswers?.data?.answers) {
          if (answer.matrixAnswer) {
            let matrixAnswer = JSON.parse(answer.matrixAnswer);
            if (option in matrixAnswer) {
              if (scale) {
                answer.scaleAnswer = matrixAnswer[option];
              } else if (choice) {
                answer.choiceAnswer = matrixAnswer[option];
              } else if (text) {
                answer.textAnswer = matrixAnswer[option];
              }
              array.push(answer);
            }
          }
        }
        return array;
      }
      return comparableAnswers?.data?.answers;
    }
    return [];
  }

  function rankingSplitScaledByRankCompared(
    choices,
    labelsMap,
    otherMap,
    limit
  ) {
    let answerMap = {};

    const splitBySurvey = viz.designSettings.split.includes("survey");
    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    forEach(choices, (c) => (blankData[c] = 0));
    for (let qId in otherMap) {
      blankData[otherMap[qId]] = 0;
    }

    forEach(splits, (seg) => {
      answerMap[seg] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let fields = getSplitLabels(splitBySurvey, answer);
      for (let split of fields) {
        if (!answerMap[split]) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            answerMap[split] = { ...blankData };
            compMap[split] = { ...blankData };
            splits.push(split);
          } else {
            continue;
          }
        }

        let weight = limit;
        for (let choice of answer.choiceAnswer) {
          let key = choice.toLowerCase();
          if (key in labelsMap) {
            let label = labelsMap[key];
            map[split][label] += weight;
          } else if (answer.questionId in otherMap) {
            // other option
            map[split][otherMap[answer.questionId]] += weight;
          }
          weight--;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let seg of splits) {
        for (let choice of choices) {
          if (answerMap[seg][choice] || compMap[seg][choice]) {
            keeping.push(seg);
            break;
          }
        }
      }
      splits = keeping;
    }

    if (viz.designSettings?.hideNonChosen) {
      keeping = [];
      for (let choice of choices) {
        for (let seg of splits) {
          if (answerMap[seg][choice] || compMap[seg][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    for (let seg of splits) {
      for (let choice of choices) {
        compMap[seg][choice] = Math.round(
          compMap[seg][choice] / viz.comparisonQs.length
        );
      }
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let sets = [
        { length: getAnswers().length, map: answerMap },
        { length: getComparableAnswers().length, map: compMap },
      ];
      for (let set of sets) {
        let length = set.length;
        let map = set.map;
        for (let choice of choices) {
          for (let seg of splits) {
            if (map[seg][choice]) {
              if (viz.designSettings.standardScale) {
                map[seg][choice] = (map[seg][choice] / length).toFixed(1);
              } else {
                let val = (map[seg][choice] / length / limit) * 100;
                map[seg][choice] = Math.round(val);
              }
            }
          }
        }
      }
    }

    let data = compileSplitComparedData(splits, answerMap, choices, compMap);
    return data;
  }

  function rankingSplitScaledByRank(choices, labelsMap, otherMap, limit) {
    if (comparing()) {
      return rankingSplitScaledByRankCompared(
        choices,
        labelsMap,
        otherMap,
        limit
      );
    }
    let answerMap = {};

    const splitBySurvey = viz.designSettings.split.includes("survey");
    let segments = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    forEach(choices, (c) => (blankData[c] = 0));
    for (let qId in otherMap) {
      blankData[otherMap[qId]] = 0;
    }

    forEach(segments, (seg) => {
      answerMap[seg] = { ...blankData };
    });

    for (let answer of getAnswers()) {
      let fields = getSplitLabels(splitBySurvey, answer);
      for (let datasetLabel of fields) {
        if (!answerMap[datasetLabel]) {
          if (
            viz.designSettings.showUndefined &&
            datasetLabel === "Undefined"
          ) {
            answerMap[datasetLabel] = { ...blankData };
            segments.push(datasetLabel);
          } else {
            continue;
          }
        }

        let weight = limit;
        for (let choice of answer.choiceAnswer) {
          let key = choice.toLowerCase();
          if (key in labelsMap) {
            let label = labelsMap[key];
            answerMap[datasetLabel][label] += weight;
          } else if (answer.questionId in otherMap) {
            // other option
            answerMap[datasetLabel][otherMap[answer.questionId]] += weight;
          }
          weight--;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let seg of segments) {
        for (let choice of choices) {
          if (answerMap[seg][choice]) {
            keeping.push(seg);
            break;
          }
        }
      }
      segments = keeping;
    }

    if (viz.designSettings?.hideNonChosen) {
      keeping = [];
      for (let choice of choices) {
        for (let seg of segments) {
          if (answerMap[seg][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let length = getAnswers().length;
      for (let choice of choices) {
        for (let seg of segments) {
          if (answerMap[seg][choice]) {
            if (viz.designSettings.standardScale) {
              answerMap[seg][choice] = (
                answerMap[seg][choice] / length
              ).toFixed(1);
            } else {
              let val = (answerMap[seg][choice] / length / limit) * 100;
              answerMap[seg][choice] = Math.round(val);
            }
          }
        }
      }
    }

    let data = compileSplitData(segments, answerMap, choices);
    return data;
  }

  function rankingPivotScaledByRankCompared(
    choices,
    labelMap,
    otherMap,
    limit
  ) {
    let pivots = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(pivots, (s) => (blankData[s] = 0));

    let answerMap = {};
    forEach(choices, (choice) => (answerMap[choice] = { ...blankData }));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = { ...blankData };
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const pivotingBySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      let fields = getPivotLabels(pivotingBySurvey, answer);
      for (let pivot of fields) {
        if (!(pivot in blankData)) {
          if (viz.designSettings.showUndefined && pivot === "Undefined") {
            for (let choice of choices) {
              answerMap[choice][pivot] = 0;
              compMap[choice][pivot] = 0;
            }
            pivots.push(pivot);
            blankData[pivot] = 0;
          } else {
            continue;
          }
        }

        let weight = limit;
        for (let choice of answer.choiceAnswer) {
          let key = choice.toLowerCase();
          if (key in labelMap) {
            map[labelMap[key]][pivot] += weight;
          } else if (answer.questionId in otherMap) {
            // other option
            map[otherMap[answer.questionId]][pivot] += weight;
          }
          weight--;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of pivots) {
        for (let choice of choices) {
          if (answerMap[choice][label] || compMap[choice][label]) {
            keeping.push(label);
            break;
          }
        }
      }
      pivots = keeping;
    }

    for (let choice of choices) {
      for (let pivot of pivots) {
        compMap[choice][pivot] = Math.round(
          compMap[choice][pivot] / viz.comparisonQs.length
        );
      }
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let sets = [
        { length: getAnswers().length, map: answerMap },
        { length: getComparableAnswers().length, map: compMap },
      ];
      for (let set of sets) {
        let map = set.map;
        let length = set.length;
        for (let choice of choices) {
          for (let label of pivots) {
            if (map[choice][label]) {
              if (viz.designSettings.standardScale) {
                map[choice][label] = (map[choice][label] / length).toFixed(1);
              } else {
                let val = (map[choice][label] / length / limit) * 100;
                map[choice][label] = Math.round(val);
              }
            }
          }
        }
      }
    }

    let data = compileSplitComparedData(choices, answerMap, pivots, compMap);

    return data;
  }

  function rankingPivotScaledByRank(choices, labelMap, otherMap, limit) {
    if (viz.designSettings.answerType === ParticipationCount) {
      return choicePivotPartCount();
    }
    if (comparing()) {
      return rankingPivotScaledByRankCompared(
        choices,
        labelMap,
        otherMap,
        limit
      );
    }

    let segments = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(segments, (s) => (blankData[s] = 0));

    let answerMap = {};
    forEach(choices, (choice) => (answerMap[choice] = { ...blankData }));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = { ...blankData };
    }

    const pivotingBySurvey = viz.pivotString.includes("survey");

    for (let answer of getAnswers()) {
      let fields = getPivotLabels(pivotingBySurvey, answer);
      for (let segment of fields) {
        if (!(segment in blankData)) {
          if (viz.designSettings.showUndefined && segment === "Undefined") {
            for (let choice of choices) {
              answerMap[choice][segment] = 0;
            }
            segments.push(segment);
            blankData[segment] = 0;
          } else {
            continue;
          }
        }

        let weight = limit;
        for (let choice of answer.choiceAnswer) {
          let key = choice.toLowerCase();
          if (key in labelMap) {
            answerMap[labelMap[key]][segment] += weight;
          } else if (answer.questionId in otherMap) {
            // other option
            answerMap[otherMap[answer.questionId]][segment] += weight;
          }
          weight--;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of segments) {
        for (let choice of choices) {
          if (answerMap[choice][label]) {
            keeping.push(label);
            break;
          }
        }
      }
      segments = keeping;
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let length = getAnswers().length;
      for (let choice of choices) {
        for (let label of segments) {
          if (answerMap[choice][label]) {
            if (viz.designSettings.standardScale) {
              answerMap[choice][label] = (
                answerMap[choice][label] / length
              ).toFixed(1);
            } else {
              let val = (answerMap[choice][label] / length / limit) * 100;
              answerMap[choice][label] = Math.round(val);
            }
          }
        }
      }
    }

    let data = compileSplitData(choices, answerMap, segments);

    return data;
  }

  function rankingScaledByRankCompared(choices, labelMap, otherMap, limit) {
    let answerMap = {};
    for (let choice of choices) {
      answerMap[choice] = 0;
    }
    let compMap = { ...answerMap };

    function putInAnswer(answer, map) {
      let weight = limit;
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          map[label] += weight;
        } else if (otherMap[answer.questionId]) {
          // other option
          map[otherMap[answer.questionId]] += weight;
        }
        weight--;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      keeping = [];
      for (let choice of choices) {
        if (answerMap[choice] || compMap[choice]) {
          keeping.push(choice);
          break;
        }
      }
      choices = keeping;
    }

    for (let choice of choices) {
      compMap[choice] = Math.round(compMap[choice] / viz.comparisonQs.length);
    }

    if (
      viz.designSettings.standardScale ||
      viz.designSettings.zeroHundredScale
    ) {
      let sets = [
        { length: getAnswers().length, map: answerMap },
        { length: getComparableAnswers().length, map: compMap },
      ];
      for (let set of sets) {
        let length = set.length;
        let map = set.map;
        for (let choice of choices) {
          if (viz.designSettings.standardScale) {
            map[choice] = (map[choice] / length).toFixed(1);
          } else {
            map[choice] = Math.round((map[choice] / length / limit) * 100);
          }
        }
      }
    }

    let data = compileComparedData(answerMap, compMap, choices);
    return data;
  }

  function rankingScaledByRank(choices, labelMap, otherMap, limit) {
    if (viz.pivotString) {
      return rankingPivotScaledByRank(choices, labelMap, otherMap, limit);
    }
    if (viz.designSettings.split) {
      return rankingSplitScaledByRank(choices, labelMap, otherMap, limit);
    }
    if (comparing()) {
      return rankingScaledByRankCompared(choices, labelMap, otherMap, limit);
    }

    let answerMap = {};
    for (let choice of choices) {
      answerMap[choice] = 0;
    }

    for (let answer of getAnswers()) {
      let weight = limit;
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          answerMap[label] += weight;
        } else if (otherMap[answer.questionId]) {
          // other option
          answerMap[otherMap[answer.questionId]] += weight;
        }
        weight--;
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      keeping = [];
      for (let choice of choices) {
        if (answerMap[choice]) {
          keeping.push(choice);
          break;
        }
      }
      choices = keeping;
    }

    let answerTallies = [];
    let length = getAnswers().length;
    for (let choice of choices) {
      if (viz.designSettings.standardScale) {
        answerTallies.push((answerMap[choice] / length).toFixed(1));
      } else if (viz.designSettings.zeroHundredScale) {
        let val = (answerMap[choice] / length / limit) * 100;
        answerTallies.push(Math.round(val));
      } else {
        answerTallies.push(answerMap[choice]);
      }
    }

    let data = compileData(choices, answerTallies);
    return data;
  }

  function getRankingCompared(choices, labelMap, otherMap, limit) {
    let answerMap = {};
    let choicesMap = {};
    for (let choice of choices) {
      choicesMap[choice] = 0;
    }

    let rankings = [];
    for (let i = 1; i <= limit; i++) {
      answerMap[i] = { ...choicesMap };
      rankings.push(i);
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let rank = 1;
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          map[rank][label]++;
        } else if (otherMap[answer.questionId]) {
          // other option
          map[rank][otherMap[answer.questionId]]++;
        }
        rank++;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let rank of rankings) {
          if (answerMap[rank][choice] || compMap[rank][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    for (let choice of choices) {
      for (let rank of rankings) {
        compMap[rank][choice] = Math.round(
          compMap[rank][choice] / viz.comparisonQs.length
        );
      }
    }

    if (viz.designSettings.answerType === Ranking) {
      [rankings, answerMap, choices] = getFlippedData(
        rankings,
        answerMap,
        choices
      );

      let extraLL = [];
      let extraAL = [];
      [extraLL, compMap, extraAL] = getFlippedData([...choices], compMap, [
        ...rankings,
      ]);
    }

    let data = compileSplitComparedData(rankings, answerMap, choices, compMap);
    return data;
  }

  function getRankingData(choices, labelMap, otherMap) {
    let limit = 0;
    for (let q of vizQs) {
      if (q.choiceQuestion.limit) {
        if (q.choiceQuestion.limit > limit) {
          limit = q.choiceQuestion.limit;
        }
      } else {
        let length = q.choiceQuestion.choices.length;
        if (q.choiceQuestion.hasOtherOption) {
          length++;
        }
        if (length > limit) {
          limit = q.choiceQuestion.choices.length;
        }
      }
    }

    if (viz.designSettings.scaleByRank) {
      return rankingScaledByRank(choices, labelMap, otherMap, limit);
    }

    if (comparing()) {
      return getRankingCompared(choices, labelMap, otherMap, limit);
    }

    let answerMap = {};
    let choicesMap = {};
    for (let choice of choices) {
      choicesMap[choice] = 0;
    }

    let rankings = [];
    for (let i = 1; i <= limit; i++) {
      answerMap[i] = { ...choicesMap };
      rankings.push(i);
    }

    for (let answer of getAnswers()) {
      let rank = 1;
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          answerMap[rank][label]++;
        } else if (otherMap[answer.questionId]) {
          // other option
          answerMap[rank][otherMap[answer.questionId]]++;
        }
        rank++;
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let rank of rankings) {
          if (answerMap[rank][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    if (viz.designSettings.answerType === Ranking) {
      [rankings, answerMap, choices] = getFlippedData(
        rankings,
        answerMap,
        choices
      );
    }

    let data = compileSplitData(rankings, answerMap, choices);
    return data;
  }

  function choicePivotPartCountCompared() {
    const showUndefined = viz.designSettings.showUndefined;

    let pivots = getLabelsFor(viz.pivotString);
    let answerMap = {};
    forEach(pivots, (seg) => (answerMap[seg] = 0));
    let compMap = { ...answerMap };

    const bySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      let fields = getPivotLabels(bySurvey, answer);
      for (let pivot of fields) {
        if (!(pivot in map)) {
          if (showUndefined && pivot === "Undefined") {
            answerMap[pivot] = 0;
            compMap[pivot] = 0;
            pivots.push(pivot);
          } else {
            continue;
          }
        }

        map[pivot] += 1;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let seg of pivots) {
        if (answerMap[seg] || compMap[seg]) {
          keeping.push(seg);
        }
      }
      pivots = keeping;
    }

    for (let seg of pivots) {
      compMap[seg] = Math.round(compMap[seg] / viz.comparisonQs.length);
    }

    let data = compileComparedData(answerMap, compMap, pivots);
    return data;
  }

  function choicePivotPartCount() {
    if (comparing()) {
      return choicePivotPartCountCompared();
    }
    const showUndefined = viz.designSettings.showUndefined;

    let segments = getLabelsFor(viz.pivotString);
    let answerMap = {};
    forEach(segments, (seg) => (answerMap[seg] = 0));

    const bySurvey = viz.pivotString.includes("survey");

    for (let answer of getAnswers()) {
      let fields = getPivotLabels(bySurvey, answer);
      for (let segmentLabel of fields) {
        if (!(segmentLabel in answerMap)) {
          if (showUndefined && segmentLabel === "Undefined") {
            answerMap[segmentLabel] = 0;
            segments.push(segmentLabel);
          } else {
            continue;
          }
        }

        answerMap[segmentLabel] += 1;
      }
    }

    let keeping = [];
    if (viz.designSettings.showNonParticipating) {
      keeping = [...segments];
    } else {
      for (let seg of segments) {
        if (answerMap[seg]) {
          keeping.push(seg);
        }
      }
    }
    segments = keeping;

    let answerTallies = [];
    for (let seg of segments) {
      answerTallies.push(answerMap[seg]);
    }

    let data = compileData(segments, answerTallies);
    return data;
  }

  function choicePivotCompared(choices, labelMap, otherMap) {
    let pivots = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(pivots, (p) => (blankData[p] = 0));

    let answerMap = {};
    forEach(choices, (choice) => (answerMap[choice] = { ...blankData }));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = { ...blankData };
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const pivotingBySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      for (let choice of answer.choiceAnswer) {
        let fields = getPivotLabels(pivotingBySurvey, answer);
        for (let pivot of fields) {
          if (!(pivot in blankData)) {
            if (viz.designSettings.showUndefined && pivot === "Undefined") {
              for (let choice of choices) {
                answerMap[choice][pivot] = 0;
                compMap[choice][pivot] = 0;
              }
              pivots.push(pivot);
              blankData[pivot] = 0;
            } else {
              continue;
            }
          }

          let key = choice.toLowerCase();
          if (key in labelMap) {
            map[labelMap[key]][pivot] += 1;
          } else if (answer.questionId in otherMap) {
            // other option
            map[otherMap[answer.questionId]][pivot] += 1;
          }
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of pivots) {
        for (let choice of choices) {
          if (answerMap[choice][label] || compMap[choice][label]) {
            keeping.push(label);
            break;
          }
        }
      }
      pivots = keeping;
    }

    for (let pivot of pivots) {
      for (let choice of choices) {
        compMap[choice][pivot] = Math.round(
          compMap[choice][pivot] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(choices, answerMap, pivots, compMap);
    return data;
  }

  function choicePivot(choices, labelMap, otherMap) {
    if (viz.designSettings.answerType === ParticipationCount) {
      return choicePivotPartCount();
    }
    if (comparing()) {
      return choicePivotCompared(choices, labelMap, otherMap);
    }

    let segments = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(segments, (s) => (blankData[s] = 0));

    let answerMap = {};
    forEach(choices, (choice) => (answerMap[choice] = { ...blankData }));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = { ...blankData };
    }

    const pivotingBySurvey = viz.pivotString.includes("survey");

    for (let answer of getAnswers()) {
      for (let choice of answer.choiceAnswer) {
        let fields = getPivotLabels(pivotingBySurvey, answer);
        for (let segment of fields) {
          if (!(segment in blankData)) {
            if (viz.designSettings.showUndefined && segment === "Undefined") {
              for (let choice of choices) {
                answerMap[choice][segment] = 0;
              }
              segments.push(segment);
              blankData[segment] = 0;
            } else {
              continue;
            }
          }

          let key = choice.toLowerCase();
          if (key in labelMap) {
            answerMap[labelMap[key]][segment] += 1;
          } else if (answer.questionId in otherMap) {
            // other option
            answerMap[otherMap[answer.questionId]][segment] += 1;
          }
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of segments) {
        for (let choice of choices) {
          if (answerMap[choice][label]) {
            keeping.push(label);
            break;
          }
        }
      }
      segments = keeping;
    }

    let data = compileSplitData(choices, answerMap, segments);

    return data;
  }

  function choiceSplitCompared(choices, choicesMap, otherMap) {
    let answerMap = {};

    const splitBySurvey = viz.designSettings.split.includes("survey");
    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    forEach(choices, (c) => (blankData[c] = 0));
    for (let qId in otherMap) {
      blankData[otherMap[qId]] = 0;
    }

    forEach(splits, (seg) => {
      answerMap[seg] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      for (let choice of answer.choiceAnswer) {
        let fields = getSplitLabels(splitBySurvey, answer);
        for (let split of fields) {
          if (!map[split]) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              answerMap[split] = { ...blankData };
              compMap[split] = { ...blankData };
              splits.push(split);
            } else {
              continue;
            }
          }

          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            map[split][label] += 1;
          } else if (answer.questionId in otherMap) {
            // other option
            map[split][otherMap[answer.questionId]] += 1;
          }
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let seg of splits) {
        for (let choice of choices) {
          if (answerMap[seg][choice] || compMap[seg][choice]) {
            keeping.push(seg);
            break;
          }
        }
      }
      splits = keeping;
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let seg of splits) {
          if (answerMap[seg][choice] || compMap[seg][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    for (let seg of splits) {
      for (let choice of choices) {
        compMap[seg][choice] = Math.round(
          compMap[seg][choice] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(splits, answerMap, choices, compMap);
    return data;
  }

  function choiceSplit(choices, choicesMap, otherMap) {
    if (comparing()) {
      return choiceSplitCompared(choices, choicesMap, otherMap);
    }
    let answerMap = {};

    const splitBySurvey = viz.designSettings.split.includes("survey");
    let segments = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    forEach(choices, (c) => (blankData[c] = 0));
    for (let qId in otherMap) {
      blankData[otherMap[qId]] = 0;
    }

    forEach(segments, (seg) => {
      answerMap[seg] = { ...blankData };
    });

    for (let answer of getAnswers()) {
      for (let choice of answer.choiceAnswer) {
        let fields = getSplitLabels(splitBySurvey, answer);
        for (let datasetLabel of fields) {
          if (!answerMap[datasetLabel]) {
            if (
              viz.designSettings.showUndefined &&
              datasetLabel === "Undefined"
            ) {
              answerMap[datasetLabel] = { ...blankData };
              segments.push(datasetLabel);
            } else {
              continue;
            }
          }

          let key = choice.toLowerCase();
          if (key in choicesMap) {
            let label = choicesMap[key];
            answerMap[datasetLabel][label] += 1;
          } else if (answer.questionId in otherMap) {
            // other option
            answerMap[datasetLabel][otherMap[answer.questionId]] += 1;
          }
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let seg of segments) {
        for (let choice of choices) {
          if (answerMap[seg][choice]) {
            keeping.push(seg);
            break;
          }
        }
      }
      segments = keeping;
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let choice of choices) {
        for (let seg of segments) {
          if (answerMap[seg][choice]) {
            keeping.push(choice);
            break;
          }
        }
      }
      choices = keeping;
    }

    let data = compileSplitData(segments, answerMap, choices);
    return data;
  }

  function choiceCompared(labels, labelMap, otherMap) {
    let answerMap = {};
    forEach(labels, (choice) => (answerMap[choice] = 0));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = 0;
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          map[label]++;
        } else if (otherMap[answer.questionId]) {
          // other option
          map[otherMap[answer.questionId]]++;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label] || compMap[label]) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    for (let label of labels) {
      compMap[label] = Math.round(compMap[label] / viz.comparisonQs.length);
    }

    let data = compileComparedData(answerMap, compMap, labels);
    return data;
  }

  function getChoiceData() {
    let labels = [];
    let labelMap = {};
    let otherMap = {};
    let otherLabel = "";

    for (let q of vizQs) {
      if (q.choiceQuestion) {
        let choices = q.choiceQuestion.choices;
        for (let choice of choices) {
          let lowered = choice.toLowerCase();
          if (!(lowered in labelMap)) {
            labelMap[lowered] = choice;
            labels.push(choice);
          }
        }
        if (q.choiceQuestion.hasOtherOption) {
          if (!otherLabel) {
            otherLabel = q.choiceQuestion.otherOptionLabel;
          }
          otherMap[q.id] = otherLabel;
        }
      }
    }
    if (otherLabel) {
      labels.push(otherLabel);
      if (comparing()) {
        for (let q of viz.comparisonQs) {
          if (q.choiceQuestion && q.choiceQuestion.hasOtherOption) {
            if (q.choiceQuestion.hasOtherOption) {
              otherMap[q.id] = otherLabel;
            }
          }
        }
      }
    }

    if (viz.designSettings.asRanking) {
      return getRankingData(labels, labelMap, otherMap);
    }

    if (viz.pivotString) {
      return choicePivot(labels, labelMap, otherMap);
    }
    if (viz?.designSettings?.split) {
      return choiceSplit(labels, labelMap, otherMap);
    }

    if (comparing()) {
      return choiceCompared(labels, labelMap, otherMap);
    }

    let answerMap = {};
    forEach(labels, (choice) => (answerMap[choice] = 0));
    for (let qId in otherMap) {
      answerMap[otherMap[qId]] = 0;
    }

    for (let answer of getAnswers()) {
      for (let choice of answer.choiceAnswer) {
        let key = choice.toLowerCase();
        if (key in labelMap) {
          let label = labelMap[key];
          answerMap[label]++;
        } else if (otherMap[answer.questionId]) {
          // other option
          answerMap[otherMap[answer.questionId]]++;
        }
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label]) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(answerMap[label]);
    }

    let data = compileData(labels, answerTallies);
    return data;
  }

  function scalePivot() {
    if (comparing()) {
      return scalePivotCompared();
    }

    let labels = [];
    let answerMap = {};

    const blankData = {
      total: 0,
      count: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
    };

    const showUndefined = viz.designSettings.showUndefined;

    const pivotBySurvey = viz.pivotString.includes("survey");
    labels = getLabelsFor(viz.pivotString);
    forEach(labels, (label) => (answerMap[label] = { ...blankData }));

    for (let answer of getAnswers()) {
      let fields = getPivotLabels(pivotBySurvey, answer);

      for (let label of fields) {
        if (!answerMap[label]) {
          if (showUndefined && label === "Undefined") {
            labels.push(label);
            answerMap[label] = { ...blankData };
          } else {
            continue;
          }
        }
        answerMap[label].count += 1;
        answerMap[label].total += answer.scaleAnswer;

        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          answerMap[label].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          answerMap[label].passives += 1;
        } else {
          answerMap[label].detractors += 1;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label].count) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    for (let label in answerMap) {
      if (answerMap[label]) {
        if (viz.designSettings.answerType === AvgScore) {
          let avg = answerMap[label].total / answerMap[label].count;
          answerMap[label] = avg.toFixed(1);
        } else if (viz.designSettings.answerType === ParticipationCount) {
          answerMap[label] = answerMap[label].count;
        } else if (viz.designSettings.answerType === NpsScore) {
          if (answerMap[label].count) {
            // Don't count it if the count was 0;
            let score =
              ((answerMap[label].promoters - answerMap[label].detractors) /
                answerMap[label].count) *
              100; //calculate nps score
            answerMap[label] = Math.round(score);
          } else {
            answerMap[label] = null;
          }
        }
      }
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(answerMap[label]);
    }

    let data = compileData(labels, answerTallies);
    return data;
  }

  function scalePivotCompared() {
    let labels = [];
    let answerMap = {};
    let compMap = {};

    const blankData = {
      total: 0,
      count: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
    };

    const pivotBySurvey = viz.pivotString.includes("survey");
    labels = getLabelsFor(viz.pivotString);
    forEach(labels, (label) => {
      answerMap[label] = { ...blankData };
      compMap[label] = { ...blankData };
    });

    function putInAnswer(answer, map) {
      let fields = getPivotLabels(pivotBySurvey, answer);
      for (let label of fields) {
        if (!map[label]) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            labels.push(label);
            answerMap[label] = { ...blankData };
            compMap[label] = { ...blankData };
          } else {
            continue;
          }
        }
        map[label].count += 1;
        map[label].total += answer.scaleAnswer;

        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          map[label].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          map[label].passives += 1;
        } else {
          map[label].detractors += 1;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label].count || compMap[label].count) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    for (let label of labels) {
      if (viz.designSettings.answerType === AvgScore) {
        let avg = answerMap[label].total / answerMap[label].count;
        answerMap[label] = avg.toFixed(1);
        avg = compMap[label].total / compMap[label].count;
        compMap[label] = avg.toFixed(1);
      } else if (viz.designSettings.answerType === ParticipationCount) {
        answerMap[label] = answerMap[label].count;
        compMap[label] = Math.round(
          compMap[label].count / viz.comparisonQs.length
        );
      } else if (viz.designSettings.answerType === NpsScore) {
        let score = 0;
        if (answerMap[label].count) {
          // Don't count it if the count was 0;
          score =
            ((answerMap[label].promoters - answerMap[label].detractors) /
              answerMap[label].count) *
            100; //calculate nps score
        }
        answerMap[label] = Math.round(score);
        if (compMap[label].count) {
          // Don't count it if the count was 0;
          let score =
            ((compMap[label].promoters - compMap[label].detractors) /
              compMap[label].count) *
            100; //calculate nps score
          compMap[label] = Math.round(score);
        } else {
          compMap[label] = null;
        }
      }
    }

    return compileComparedData(answerMap, compMap, labels);
  }

  function textPivotAndSplitCompared() {
    let splitFields = getLabelsFor(viz.designSettings.split);
    let pivotFields = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(pivotFields, (p) => (blankData[p] = 0));
    let answerMap = {};
    forEach(splitFields, (s) => {
      answerMap[s] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const showUndefined = viz.designSettings.showUndefined;
    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      let answerSplits = getSplitLabels(splitBySurvey, answer);
      for (let split of answerSplits) {
        if (!map[split]) {
          if (showUndefined && split === "Undefined") {
            // Add a new dataset
            splitFields.push(split);
            answerMap[split] = { ...blankData };
            compMap[split] = { ...blankData };
          } else {
            continue;
          }
        }
        let answerPivots = getPivotLabels(pivotBySurvey, answer);
        for (let pivot of answerPivots) {
          if (!(pivot in map[split])) {
            if (showUndefined && pivot === "Undefined") {
              // Add the option to every dataset
              pivotFields.push(pivot);
              blankData[pivot] = 0;
              for (let seg of splitFields) {
                answerMap[seg][pivot] = 0;
                compMap[seg][pivot] = 0;
              }
            } else {
              continue;
            }
          }

          map[split][pivot] += 1;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split of splitFields) {
        for (let pivot of pivotFields) {
          if (answerMap[split][pivot] || compMap[split][pivot]) {
            keeping.push(split);
            break;
          }
        }
      }
      splitFields = keeping;

      keeping = [];
      for (let pivot of pivotFields) {
        for (let split of splitFields) {
          if (answerMap[split][pivot] || compMap[split][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivotFields = keeping;
    }

    for (let split of splitFields) {
      for (let pivot of pivotFields) {
        compMap[split][pivot] = Math.round(
          compMap[split][pivot] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(
      splitFields,
      answerMap,
      pivotFields,
      compMap
    );
    return data;
  }

  function textPivotAndSplit() {
    if (comparing()) {
      return textPivotAndSplitCompared();
    }

    let splitFields = getLabelsFor(viz.designSettings.split);
    let pivotFields = getLabelsFor(viz.pivotString);

    let blankData = {};
    forEach(pivotFields, (p) => (blankData[p] = 0));
    let answerMap = {};
    forEach(splitFields, (s) => {
      answerMap[s] = { ...blankData };
    });

    const showUndefined = viz.designSettings.showUndefined;
    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.pivotString.includes("survey");

    for (let answer of getAnswers()) {
      let answerSplits = getSplitLabels(splitBySurvey, answer);
      for (let split of answerSplits) {
        if (!answerMap[split]) {
          if (showUndefined && split === "Undefined") {
            // Add a new dataset
            splitFields.push(split);
            answerMap[split] = { ...blankData };
          } else {
            continue;
          }
        }
        let answerPivots = getPivotLabels(pivotBySurvey, answer);
        for (let pivot of answerPivots) {
          if (!(pivot in answerMap[split])) {
            if (showUndefined && pivot === "Undefined") {
              // Add the option to every dataset
              pivotFields.push(pivot);
              blankData[pivot] = 0;
              for (let seg of splitFields) {
                if (!(pivot in answerMap[seg])) {
                  answerMap[seg][pivot] = 0;
                }
              }
            } else {
              continue;
            }
          }

          answerMap[split][pivot] += 1;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split of splitFields) {
        for (let pivot of pivotFields) {
          if (answerMap[split][pivot]) {
            keeping.push(split);
            break;
          }
        }
      }
      splitFields = keeping;

      keeping = [];
      for (let pivot of pivotFields) {
        for (let split of splitFields) {
          if (answerMap[split][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivotFields = keeping;
    }

    let data = compileSplitData(splitFields, answerMap, pivotFields);
    return data;
  }

  function textPivotCompared() {
    let labels = [];
    let answerMap = {};

    labels = getLabelsFor(viz.pivotString);
    forEach(labels, (label) => (answerMap[label] = 0));
    let compMap = { ...answerMap };

    const pivotBySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      let fields = getPivotLabels(pivotBySurvey, answer);

      for (let label of fields) {
        if (!(label in answerMap)) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            labels.push(label);
            answerMap[label] = 0;
            compMap[label] = 0;
          } else {
            continue;
          }
        }

        map[label] += 1;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label] || compMap[label]) {
          keeping.push(label);
        }
      }

      labels = keeping;
    }

    for (let label of labels) {
      compMap[label] = Math.round(compMap[label] / viz.comparisonQs.length);
    }

    let data = compileComparedData(answerMap, compMap, labels);
    return data;
  }

  function textPivot() {
    if (viz.designSettings.split) {
      return textPivotAndSplit();
    }
    if (comparing()) {
      return textPivotCompared();
    }

    let answerMap = {};
    let labels = getLabelsFor(viz.pivotString);
    forEach(labels, (label) => (answerMap[label] = 0));

    const pivotBySurvey = viz.pivotString.includes("survey");

    for (let answer of getAnswers()) {
      let fields = getPivotLabels(pivotBySurvey, answer);
      for (let label of fields) {
        if (!(label in answerMap)) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            labels.push(label);
            answerMap[label] = 0;
          } else {
            continue;
          }
        }

        answerMap[label] += 1;
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label]) {
          keeping.push(label);
        }
      }

      labels = keeping;
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(answerMap[label]);
    }

    let data = compileData(labels, answerTallies);
    return data;
  }

  function plainTextData() {
    if (comparing()) {
      let map = {
        Responses: getAnswers().length,
      };
      let compMap = {
        Responses: Math.round(
          getComparableAnswers().length / viz.comparisonQs.length
        ),
      };

      return compileComparedData(map, compMap, ["Responses"]);
    }

    let count = getAnswers().length;
    let data = compileData([""], [count]);
    return data;
  }

  function scalePivotNpsSplit() {
    let answerMap = {};
    let splits = getLabelsFor(viz?.designSettings?.split);
    for (let split of splits) {
      answerMap[split] = {
        promoters: 0,
        detractors: 0,
        passives: 0,
      };
    }

    const splitBySurvey = viz?.designSettings?.split?.includes("survey");

    for (let answer of getAnswers()) {
      let splitFields = getSplitLabels(splitBySurvey, answer);
      for (let split of splitFields) {
        if (!answerMap[split]) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            answerMap[split] = {
              promoters: 0,
              detractors: 0,
              passives: 0,
            };
            splits.push(split);
          } else {
            continue;
          }
        }
        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          answerMap[split].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          answerMap[split].passives += 1;
        } else {
          answerMap[split].detractors += 1;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split in answerMap) {
        let record = answerMap[split];
        if (record.promoters || record.detractors || record.passives) {
          keeping.push(split);
        }
      }
      splits = keeping;
    }

    let labels = ["promoters", "passives", "detractors"];
    let data = compileSplitData(splits, answerMap, labels);
    data.labels = ["Promoters", "Passives", "Detractors"];

    return data;
  }

  function scalePivotNpsSplitCompared() {
    let answerMap = {};
    let compMap = {};
    let splits = getLabelsFor(viz?.designSettings?.split);
    let blankData = { promoters: 0, detractors: 0, passives: 0 };
    for (let split of splits) {
      answerMap[split] = { ...blankData };
      compMap[split] = { ...blankData };
    }

    const splitBySurvey = viz?.designSettings?.split?.includes("survey");

    function putInAnswer(answer, map) {
      let splitFields = getSplitLabels(splitBySurvey, answer);
      for (let split of splitFields) {
        if (!map[split]) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            answerMap[split] = { ...blankData };
            compMap[split] = { ...blankData };
            splits.push(split);
          } else {
            continue;
          }
        }
        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          map[split].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          map[split].passives += 1;
        } else {
          map[split].detractors += 1;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }

    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let split in answerMap) {
        let record = answerMap[split];
        if (record.promoters || record.detractors || record.passives) {
          keeping.push(split);
        }
      }

      splits = keeping;
    }

    let labels = ["promoters", "passives", "detractors"];

    for (let split of splits) {
      for (let label of labels) {
        compMap[split][label] = Math.round(
          compMap[split][label] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(splits, answerMap, labels, compMap);
    data.labels = ["Promoters", "Passives", "Detractors"];

    return data;
  }

  function scalePivotNps() {
    if (comparing()) {
      return scalePivotNpsCompared();
    }
    if (viz.designSettings.split) {
      return scalePivotNpsSplit();
    }

    let promoters = 0;
    let detractors = 0;
    let passives = 0;

    for (let answer of getAnswers()) {
      // if (answer.participation.contact.firstName === "Syed") {
      //   debugger;
      // }
      if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
        promoters += 1;
      } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
        passives += 1;
      } else {
        detractors += 1;
      }
    }

    let labels = ["Promoters", "Passives", "Detractors"];
    let answerTallies = [promoters, passives, detractors];
    let data = compileData(labels, answerTallies);
    return data;
  }

  function scalePivotNpsCompared() {
    if (viz.designSettings.split) {
      return scalePivotNpsSplitCompared();
    }
    let pivots = ["Promoters", "Passives", "Detractors"];
    let answerMap = {};
    forEach(pivots, (p) => (answerMap[p] = 0));
    let compMap = { ...answerMap };

    function putInAnswer(answer, map) {
      if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
        map.Promoters += 1;
      } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
        map.Passives += 1;
      } else {
        map.Detractors += 1;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    for (let pivot of pivots) {
      compMap[pivot] = Math.round(compMap[pivot] / viz.comparisonQs.length);
    }

    return compileComparedData(answerMap, compMap, pivots);
  }

  function scalePivotAndSplitNpsCompared() {
    let axisLabels = getLabelsFor(viz.pivotString);
    let legendLabels = ["Promoters", "Passives", "Detractors"];
    if (viz.designSettings.includeOverall) {
      legendLabels.push("Overall");
    }

    let answerMap = {};
    for (let dataset of legendLabels) {
      answerMap[dataset] = {};
      for (let label of axisLabels) {
        answerMap[dataset][label] = 0;
      }
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const pivotBySurvey = viz.pivotString.includes("survey");

    function putInAnswer(answer, map) {
      let dataset = "";
      if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
        dataset = "Promoters";
      } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
        dataset = "Passives";
      } else if (answer.scaleAnswer <= 6) {
        dataset = "Detractors";
      }
      if (!dataset) {
        return;
      }

      let pivotFields = getPivotLabels(pivotBySurvey, answer);
      for (let label of pivotFields) {
        if (!(label in map[dataset])) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            for (let nps of legendLabels) {
              answerMap[nps][label] = 0;
              compMap[nps][label] = 0;
            }
            axisLabels.push(label);
          } else {
            continue;
          }
        }
        map[dataset][label] += 1;
        if (viz.designSettings.includeOverall) {
          map.Overall[label] += 1;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of axisLabels) {
        for (let nps of legendLabels) {
          if (answerMap[nps][label] || compMap[nps][label]) {
            keeping.push(label);
            break;
          }
        }
      }
      axisLabels = keeping;
    }

    for (let nps of legendLabels) {
      for (let label of axisLabels) {
        compMap[nps][label] = Math.round(
          compMap[nps][label] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(
      legendLabels,
      answerMap,
      axisLabels,
      compMap
    );
    return data;
  }

  function scalePivotAndSplitNps() {
    let axisLabels = getLabelsFor(viz.pivotString);
    let legendLabels = ["Promoters", "Passives", "Detractors"];
    if (viz.designSettings.includeOverall) {
      legendLabels.push("Overall");
    }

    let answerMap = {};
    for (let dataset of legendLabels) {
      answerMap[dataset] = {};
      for (let label of axisLabels) {
        answerMap[dataset][label] = 0;
      }
    }

    const pivotBySurvey = viz.pivotString.includes("survey");
    for (let answer of getAnswers()) {
      // if (answer.participation.contact.firstName === "Clayton") {
      //   console.log(answer.participation.contact);
      //   let fields = JSON.parse(answer.participation.contact.customField);
      //   let realFields = JSON.parse(fields);
      //   debugger;
      // }
      let dataset = "";
      if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
        dataset = "Promoters";
      } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
        dataset = "Passives";
      } else if (answer.scaleAnswer <= 6) {
        dataset = "Detractors";
      }
      if (!dataset) {
        continue;
      }

      let pivotFields = getPivotLabels(pivotBySurvey, answer);
      for (let label of pivotFields) {
        if (!(label in answerMap[dataset])) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            forEach(legendLabels, (nps) => (answerMap[nps][label] = 0));
            axisLabels.push(label);
          } else {
            continue;
          }
        }
        answerMap[dataset][label] += 1;
        if (viz.designSettings.includeOverall) {
          answerMap.Overall[label] += 1;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      let keeping = [];
      for (let label of axisLabels) {
        for (let nps of legendLabels) {
          if (answerMap[nps][label]) {
            // keep if at least one point towards any
            keeping.push(label);
            break;
          }
        }
      }
      axisLabels = keeping;
    }

    let data = compileSplitData(legendLabels, answerMap, axisLabels);
    return data;
  }

  function scalePivotAndSplitCompared() {
    if (viz.designSettings.split === "nps") {
      return scalePivotAndSplitNpsCompared();
    }

    const blankData = {
      count: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
    };

    let pivots = getLabelsFor(viz.pivotString);
    let splits = getLabelsFor(viz.designSettings.split);
    let answerMap = {};

    for (let seg of splits) {
      answerMap[seg] = {};
      for (let axisLabel of pivots) {
        answerMap[seg][axisLabel] = { ...blankData };
      }
    }
    let compMap = JSON.parse(JSON.stringify(answerMap));

    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.pivotString.includes("survey");
    const showUndefined = viz.designSettings.showUndefined;

    function putInAnswer(answer, map) {
      let splitFields = getSplitLabels(splitBySurvey, answer);
      for (let split of splitFields) {
        if (!map[split]) {
          if (showUndefined && split === "Undefined") {
            // Add a new dataset
            splits.push(split);
            answerMap[split] = {};
            compMap[split] = {};
            for (let pivot of pivots) {
              answerMap[split][pivot] = { ...blankData };
              compMap[split][pivot] = { ...blankData };
            }
          } else {
            continue;
          }
        }
        let pivotFields = getPivotLabels(pivotBySurvey, answer);
        for (let pivot of pivotFields) {
          if (!map[split][pivot]) {
            if (showUndefined && pivot === "Undefined") {
              // Add the option to every dataset
              for (let dataset of splits) {
                answerMap[dataset][pivot] = { ...blankData };
                compMap[dataset][pivot] = { ...blankData };
              }
              pivots.push(pivot);
            } else {
              continue;
            }
          }

          if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
            map[split][pivot].promoters += 1;
          } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
            map[split][pivot].passives += 1;
          } else {
            map[split][pivot].detractors += 1;
          }
          map[split][pivot].count += 1;
          map[split][pivot].total += answer.scaleAnswer;
        }
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keepingAxis = [];
      for (let label of pivots) {
        for (let seg of splits) {
          if (answerMap[seg][label].count || compMap[seg][label].count) {
            keepingAxis.push(label);
            break;
          }
        }
      }
      pivots = keepingAxis;

      let keepingLegend = [];
      for (let seg of splits) {
        for (let col of pivots) {
          if (answerMap[seg][col].count || compMap[seg][col].count) {
            keepingLegend.push(seg);
            break;
          }
        }
      }
      splits = keepingLegend;
    }

    const vertAxis = viz.designSettings.answerType;
    for (let split of splits) {
      for (let pivot of pivots) {
        if (vertAxis === AvgScore) {
          if (answerMap[split][pivot].count === 0) {
            answerMap[split][pivot] = 0;
          } else {
            let avg =
              answerMap[split][pivot].total / answerMap[split][pivot].count;
            answerMap[split][pivot] = avg.toFixed(1);
          }
          if (compMap[split][pivot].count === 0) {
            compMap[split][pivot] = 0;
          } else {
            let avg = compMap[split][pivot].total / compMap[split][pivot].count;
            compMap[split][pivot] = avg.toFixed(1);
          }
        } else if (vertAxis === ParticipationCount) {
          answerMap[split][pivot] = answerMap[split][pivot].count;
          compMap[split][pivot] = Math.round(
            compMap[split][pivot].count / viz.comparisonQs.length
          );
        } else if (vertAxis === NpsScore) {
          if (answerMap[split][pivot].count) {
            // Don't count it if the count was 0;
            let score =
              ((answerMap[split][pivot].promoters -
                answerMap[split][pivot].detractors) /
                answerMap[split][pivot].count) *
              100; //calculate nps score
            answerMap[split][pivot] = Math.round(score);
          } else {
            answerMap[split][pivot] = null;
          }

          if (compMap[split][pivot].count) {
            let score =
              ((compMap[split][pivot].promoters -
                compMap[split][pivot].detractors) /
                compMap[split][pivot].count) *
              100;
            compMap[split][pivot] = Math.round(score);
          } else {
            compMap[split][pivot] = null;
          }
        }
      }
    }

    let data = compileSplitComparedData(splits, answerMap, pivots, compMap);
    return data;
  }

  function scalePivotAndSplit() {
    if (comparing()) {
      return scalePivotAndSplitCompared();
    }
    if (viz.designSettings.split === "nps") {
      return scalePivotAndSplitNps();
    }

    const blankData = {
      count: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
    };

    let pivots = getLabelsFor(viz.pivotString);
    let splits = getLabelsFor(viz.designSettings.split);
    let answerMap = {};

    for (let seg of splits) {
      answerMap[seg] = {};
      for (let axisLabel of pivots) {
        answerMap[seg][axisLabel] = { ...blankData };
      }
    }

    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.pivotString.includes("survey");
    let showUndefined = viz.designSettings.showUndefined;

    for (let answer of getAnswers()) {
      let splitFields = getSplitLabels(splitBySurvey, answer);
      for (let split of splitFields) {
        if (!answerMap[split]) {
          if (showUndefined && split === "Undefined") {
            // Add a new dataset
            splits.push(split);
            answerMap[split] = {};
            for (let pivot of pivots) {
              answerMap[split][pivot] = { ...blankData };
            }
          } else {
            continue;
          }
        }
        let pivotFields = getPivotLabels(pivotBySurvey, answer);
        for (let pivot of pivotFields) {
          if (!answerMap[split][pivot]) {
            if (showUndefined && pivot === "Undefined") {
              // Add the option to every dataset
              for (let dataset of splits) {
                answerMap[dataset][pivot] = { ...blankData };
              }
              pivots.push(pivot);
            } else {
              continue;
            }
          }

          if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
            answerMap[split][pivot].promoters += 1;
          } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
            answerMap[split][pivot].passives += 1;
          } else {
            answerMap[split][pivot].detractors += 1;
          }
          answerMap[split][pivot].count += 1;
          answerMap[split][pivot].total += answer.scaleAnswer;
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keepingAxis = [];
      for (let pivot of pivots) {
        for (let split of splits) {
          if (answerMap[split][pivot].count) {
            keepingAxis.push(pivot);
            break;
          }
        }
      }
      pivots = keepingAxis;

      let keepingLegend = [];
      for (let split of splits) {
        for (let pivot of pivots) {
          if (answerMap[split][pivot].count) {
            keepingLegend.push(split);
            break;
          }
        }
      }
      splits = keepingLegend;
    }

    const vertAxis = viz.designSettings.answerType;
    for (let dataset in answerMap) {
      for (let segment in answerMap[dataset]) {
        if (answerMap[dataset][segment]) {
          if (vertAxis === AvgScore) {
            if (answerMap[dataset][segment].count === 0) {
              answerMap[dataset][segment] = 0;
            } else {
              let avg =
                answerMap[dataset][segment].total /
                answerMap[dataset][segment].count;
              answerMap[dataset][segment] = avg.toFixed(1);
            }
          } else if (vertAxis === ParticipationCount) {
            answerMap[dataset][segment] = answerMap[dataset][segment].count;
          } else if (vertAxis === NpsScore) {
            if (answerMap[dataset][segment].count) {
              // Don't count it if the count was 0;
              let score =
                ((answerMap[dataset][segment].promoters -
                  answerMap[dataset][segment].detractors) /
                  answerMap[dataset][segment].count) *
                100; //calculate nps score
              answerMap[dataset][segment] = Math.round(score);
            } else {
              answerMap[dataset][segment] = null;
            }
          }
        }
      }
    }

    let data = compileSplitData(splits, answerMap, pivots);

    return data;
  }

  function pivotAScaleQ() {
    if (viz.pivotString === "nps") {
      return scalePivotNps();
    }
    if (viz.designSettings.split) {
      return scalePivotAndSplit();
    }
    return scalePivot();
  }

  function goodOlNormalScaleData(labels) {
    if (comparing()) {
      return goodOlNormalScaleDataCompared(labels);
    }
    let answerCountMap = {};

    forEach(labels, (number) => (answerCountMap[number] = 0));

    for (let answer of getAnswers()) {
      answerCountMap[answer.scaleAnswer] += 1;
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let label of labels) {
        if (answerCountMap[label]) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    let answerTallies = [];
    for (let label of labels) {
      answerTallies.push(answerCountMap[label]);
    }
    let data = compileData(labels, answerTallies);
    return data;
  }

  function goodOlNormalScaleDataCompared(labels) {
    let answerMap = {};
    forEach(labels, (number) => (answerMap[number] = 0));
    let compMap = { ...answerMap };

    for (let answer of getAnswers()) {
      answerMap[answer.scaleAnswer] += 1;
    }

    for (let answer of getComparableAnswers()) {
      compMap[answer.scaleAnswer] += 1;
    }

    for (let num of labels) {
      compMap[num] = Math.round(compMap[num] / viz.comparisonQs.length);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let label of labels) {
        if (answerMap[label] || compMap[label]) {
          keeping.push(label);
        }
      }
      labels = keeping;
    }

    return compileComparedData(answerMap, compMap, labels);
  }

  function scaleSplitCompared(range) {
    let split = viz.designSettings.split;
    const splitBySurvey = split.includes("survey");

    let splits = getLabelsFor(split);
    const blankData = {};
    forEach(range, (num) => (blankData[num] = 0));

    let answerMap = {};
    forEach(splits, (field) => {
      answerMap[field] = { ...blankData };
    });
    let compMap = JSON.parse(JSON.stringify(answerMap));

    function putInAnswer(answer, map) {
      let fields = getSplitLabels(splitBySurvey, answer);
      for (let split of fields) {
        if (!map[split]) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            answerMap[split] = { ...blankData };
            compMap[split] = { ...blankData };
            splits.push(split);
          } else {
            continue;
          }
        }
        map[split][answer.scaleAnswer] += 1;
      }
    }

    for (let answer of getAnswers()) {
      putInAnswer(answer, answerMap);
    }
    for (let answer of getComparableAnswers()) {
      putInAnswer(answer, compMap);
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let num of range) {
        for (let split of splits) {
          if (answerMap[split][num] || compMap[split][num]) {
            keeping.push(num);
            break;
          }
        }
      }
      range = [...keeping];
    }

    if (!viz.designSettings.showNonParticipating) {
      let keepingLegend = [];
      for (let split of splits) {
        for (let num of range) {
          if (answerMap[split][num] || compMap[split][num]) {
            keepingLegend.push(split);
            break;
          }
        }
      }
      splits = keepingLegend;
    }

    for (let split of splits) {
      for (let num of range) {
        compMap[split][num] = Math.round(
          compMap[split][num] / viz.comparisonQs.length
        );
      }
    }

    let data = compileSplitComparedData(splits, answerMap, range, compMap);
    return data;
  }

  function scaleSplit(axisLabels) {
    if (comparing()) {
      return scaleSplitCompared(axisLabels);
    }
    let answerCountMap = {};
    let split = viz.designSettings.split;
    const splitBySurvey = split.includes("survey");

    let properties = getLabelsFor(split);
    forEach(properties, (field) => {
      answerCountMap[field] = {};
      forEach(axisLabels, (num) => (answerCountMap[field][num] = 0));
    });

    for (let answer of getAnswers()) {
      let fields = getSplitLabels(splitBySurvey, answer);
      for (let dataset of fields) {
        if (!answerCountMap[dataset]) {
          if (viz.designSettings.showUndefined && dataset === "Undefined") {
            answerCountMap[dataset] = {};
            forEach(
              axisLabels,
              (label) => (answerCountMap[dataset][label] = 0)
            );
            properties.push(dataset);
          } else {
            continue;
          }
        }
        answerCountMap[dataset][answer.scaleAnswer] += 1;
      }
    }

    if (viz.designSettings?.hideNonChosen) {
      let keeping = [];
      for (let number of axisLabels) {
        for (let seg of properties) {
          if (answerCountMap[seg][number]) {
            keeping.push(number);
            break;
          }
        }
      }
      axisLabels = [...keeping];
    }

    if (!viz.designSettings.showNonParticipating) {
      let keepingLegend = [];
      for (let seg of properties) {
        for (let num of axisLabels) {
          if (answerCountMap[seg][num]) {
            keepingLegend.push(seg);
            break;
          }
        }
      }
      properties = keepingLegend;
    }

    let data = compileSplitData(properties, answerCountMap, axisLabels);
    return data;
  }

  // function textSplit(labels) {
  //   let answerCountMap = {};
  //   let split = viz.designSettings.split;
  //   const splitBySurvey = split.includes("survey");

  //   for (let answer of fetchAnswers.data?.answers) {
  //       let fields = getSplitLabels(splitBySurvey, answer);
  //       for (let dataset of fields) {
  //         if (!answerCountMap[dataset]) {
  //           answerCountMap[dataset] = {};
  //           labels.forEach((label) => (answerCountMap[dataset][label] = 0));
  //         }
  //         answerCountMap[dataset][answer.textAnswer] += 1;
  //       }
  //   }

  //   let data = compileSplitData(labels, answerCountMap);
  //   return data;
  // }

  function getScaleData() {
    let labels = [];

    if (viz.designSettings.answerType === Flywheel) {
      return getFlywheelData();
    }

    if (viz.pivotString) {
      return pivotAScaleQ();
    }

    for (let question of vizQs) {
      for (
        let i = question.scaleQuestion.min;
        i <= question.scaleQuestion.max;
        i += question.scaleQuestion.step
      ) {
        if (!labels.includes(i)) {
          labels.push(i);
        }
      }
    }

    if (viz.designSettings.split) {
      return scaleSplit(labels);
    }
    return goodOlNormalScaleData(labels);
  }

  function getTextData() {
    if (viz.pivotString) {
      return textPivot();
    }

    return plainTextData();
  }

  function getPivotAndSplitN() {
    // Include participants who had at least one pivot or split field
    // Don't include participants who didn't have a pivot or split field
    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.designSettings.split.includes("survey");
    let count = 0;
    for (let answer of getAnswers()) {
      let splitFields = getSplitLabels(splitBySurvey, answer);
      let pivotFields = getPivotLabels(pivotBySurvey, answer);
      // Check if they fall at least in one bucket/label
      let noSplit = splitFields.length == 1 && splitFields[0] === "Undefined";
      let noPivot = pivotFields.length == 1 && pivotFields[0] === "Undefined";
      if (!(noSplit && noPivot)) {
        count++;
      }
    }
    setN(count);
  }

  function getPivotN() {
    const pivotBySurvey = viz.pivotString.includes("survey");
    let count = 0;
    for (let answer of getAnswers()) {
      let fields = getPivotLabels(pivotBySurvey, answer);
      // Check if they fall at least in one other bucket/label
      if (!(fields.length == 1 && fields[0] === "Undefined")) {
        count++;
      }
    }
    setN(count);
  }

  function getSplitN() {
    const splitBySurvey = viz.designSettings.split.includes("survey");
    let count = 0;
    for (let answer of getAnswers()) {
      let fields = getSplitLabels(splitBySurvey, answer);
      // Check if they fall at least in one other bucket/label
      if (!(fields.length == 1 && fields[0] === "Undefined")) {
        count++;
      }
    }
    setN(count);
  }

  function getN() {
    if (
      !viz.designSettings.showUndefined &&
      (viz.pivotString || viz.designSettings.split)
    ) {
      if (viz.pivotString) {
        if (viz.designSettings.split) {
          // Pivot & Split
          getPivotAndSplitN();
        }
        // Pivot
        getPivotN();
      } else if (viz.designSettings.split) {
        // Split
        getSplitN();
      }
    } else {
      // None
      setN(getAnswers().length);
    }
  }

  const getData = () => {
    if (data) {
      return data;
    }

    if (newChart && vizQs.length === 0) {
      setData(fakeData);
      return fakeData;
    }

    let chartData;
    let question = vizQs.length ? vizQs[0] : null;
    if (!question) {
      return { labels: {}, datasets: {} };
    }

    if (question.matrixQuestion && !viz.designSettings.option) {
      chartData = getMatrixData();
    } else if (question?.choiceQuestion) {
      chartData = getChoiceData();
    } else if (question?.scaleQuestion) {
      chartData = getScaleData();
    } else if (question?.textQuestion) {
      chartData = getTextData();
    }

    if (viz.type === LineType) {
      getLineData(chartData);
    }

    setData(chartData);

    if (viz.designSettings.showN) {
      getN();
    }

    return chartData;
  };

  function getLineData(data) {
    let settings = viz.designSettings;
    //so updates to the settings gets recognized by the useEffect in Chart.jsx
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].pointRadius = settings.pointRadius;
      data.datasets[i].borderWidth = settings.lineGraphWidth;
      data.datasets[i].pointBorderWidth = 0;
      data.datasets[i].borderRadius = settings.borderRadius
        ? settings.borderRadius
        : 0;
      data.datasets[i].borderSkipped = settings.borderSkipped;
    }

    if (data.datasets.length > 1 || data.datasets[0]?.label) {
      // split
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].borderColor = data.datasets[i].backgroundColor[0];
      }
    } else {
      data.datasets[0].borderColor = settings.lineGraphColor;
      if (settings.hasUniformPointColor) {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
          data.datasets[0].backgroundColor[i] = settings.uniformPointColor;
        }
      }
    }

    return data;
  }

  function saveData(data) {
    updateViz.mutate({
      id: viz.id,
      data: { data: typeof data != "string" ? JSON.stringify(data) : data },
    });
  }

  function getStyle() {
    return {
      color: viz.designSettings.titleColor,
      fontSize: viz.designSettings.valueTitleSize,
    };
  }

  function closeDrill() {
    setDrill(null);
    setShowDrill(false);
    setChartData(null);
    if (setAbove) {
      setAbove(null);
    }
  }

  function onSegClick(segment, dataset, dataIndex, datasetIndex) {
    if (vizQs.length === 0 || !role.canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      let replacement = -1;
      let lookFor = splitSomehow(viz) || comparing() ? dataset : segment;
      for (let one in viz.designSettings.segLabelReplacements) {
        if (viz.designSettings.segLabelReplacements[one] === lookFor) {
          replacement = parseInt(one);
        }
      }
      if (replacement > -1) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.segLabelReplacements;
        viz.designSettings.segLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Get the data normally (inside getData() but past the initial 'return data')
        let chartData;
        if (vizQs[0].matrixQuestion && !viz.designSettings.option) {
          chartData = getMatrixData();
        } else if (vizQs[0]?.choiceQuestion) {
          chartData = getChoiceData();
        } else if (vizQs[0]?.scaleQuestion) {
          chartData = getScaleData();
        } else if (vizQs[0]?.textQuestion) {
          chartData = getTextData();
        }
        // Replace sort and replacements
        viz.designSettings.segLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        if (splitting(viz)) {
          // Splitting: dataset is the replacement - the split
          dataset = chartData.datasets[replacement].label;
        } else {
          // Neither: segment is the replacement - Answer
          segment = chartData.labels[replacement];
        }
      }
    }

    if (
      viz.designSettings?.axisLabelReplacements &&
      Object.keys(viz.designSettings.axisLabelReplacements).length > 0
    ) {
      let replace = false;
      for (let one in viz.designSettings.axisLabelReplacements) {
        if (dataIndex === parseInt(one)) {
          replace = true;
        }
      }
      if (replace) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.axisLabelReplacements;
        viz.designSettings.axisLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Get the data normally (inside getData() but past the initial 'return data')
        let chartData;
        if (vizQs[0].matrixQuestion && !viz.designSettings.option) {
          chartData = getMatrixData();
        } else if (vizQs[0]?.choiceQuestion) {
          chartData = getChoiceData();
        } else if (vizQs[0]?.scaleQuestion) {
          chartData = getScaleData();
        } else if (vizQs[0]?.textQuestion) {
          chartData = getTextData();
        }
        // Replace sort and replacements
        viz.designSettings.axisLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        segment = chartData.labels[dataIndex];
      }
    }

    let otherOption = "";
    // check for other options
    for (let question of vizQs) {
      if (question.choiceQuestion && question.choiceQuestion.hasOtherOption) {
        let lookFor =
          viz.pivotString && viz.designSettings.split === "choices"
            ? dataset
            : segment;
        let otherLabel = question.choiceQuestion.otherOptionLabel;
        if (otherLabel === lookFor) {
          if (viz.pivotString && viz.designSettings.split === "choices") {
            // pivoting while splitting choices
            dataset = otherLabel;
          } else {
            // for split only or for normal
            segment = otherLabel;
          }
          otherOption = otherLabel;
        }
      }
    }

    if (viz.designSettings.answerType === Ranking) {
      let x = segment;
      segment = dataset;
      dataset = x;
    }

    let setup = {
      segment: segment,
      dataset: dataset,
      split: viz.designSettings.split,
      pivot: viz.pivotString,
      otherOption: otherOption,
      asRanking:
        viz.designSettings.asRanking && !viz.designSettings.scaleByRank,
    };

    if (comparing()) {
      let comparedLabel = comparisonLabel();

      if (
        viz.designSettings.split ||
        (vizQs[0].matrixQuestion &&
          !viz.designSettings.option &&
          (!vizQs[0].textQuestion ||
            (vizQs[0].textQuestion && viz.pivotString))) ||
        (viz.designSettings.asRanking && !viz.designSettings.scaleByRank)
      ) {
        if (
          typeof dataset === "string" &&
          dataset.includes(comparedLabel + " | ")
        ) {
          setup.useCompared = true;
          let ind = dataset.indexOf("|");
          setup.dataset = dataset.slice(ind + 2);
        }
      } else if (dataset === comparedLabel) {
        setup.useCompared = true;
        setup.split = "";
      }
    }

    if (viz.designSettings.includeOverall) {
      let lookFor = viz.designSettings.overallLabel
        ? viz.designSettings.overallLabel
        : "Overall";
      if (typeof dataset === "string" && dataset.includes(lookFor)) {
        setup.split = "";
      }
    }

    setDrill(setup);

    if (setAbove) {
      setAbove(
        <Table
          answers={
            drill && drill.useCompared ? getComparableAnswers() : getAnswers()
          }
          filters={filters}
          onClose={closeDrill}
          viz={viz}
          projects={
            drill && drill.useCompared
              ? getComparableProjects.data?.survey
              : getProjects.data?.survey
          }
          surveyTags={getSurveyTags.data.tags}
          drill={drill}
          titleStyle={getStyle()}
          custom_fields={custom_fields}
          togglespreadsheet
        />
      );
    } else {
      setShowDrill(true);
    }
  }

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }
    if (newChart && vizQs.length == 0) {
      return "Sample Data";
    }

    return viz.title;
  }

  return (
    <>
      {(fetchAnswers.isLoading ||
        comparableAnswers.isLoading ||
        getProjects.isLoading ||
        getComparableProjects.isLoading ||
        getSurveyTags.isLoading ||
        colorPalette.isLoading) &&
        !thumbnail && <Loading height={height}></Loading>}
      {fetchAnswers.isSuccess &&
        getProjects.isSuccess &&
        getComparableProjects.isSuccess &&
        getSurveyTags.isSuccess &&
        colorPalette.isSuccess &&
        comparableAnswers.isSuccess && (
          <>
            {!showDrill && !seeData && (
              <>
                <div
                  className={styles.chartWithTitle}
                  id={viz.id + idAddOn}
                  style={thumbnail ? { gap: "0" } : undefined}
                >
                  {viz.designSettings.hasTitle && (
                    <div
                      className={styles.titleContainer}
                      id={"title for " + viz.id + idAddOn}
                      style={{
                        minHeight: thumbnail ? "25px" : "",
                        alignItems: viz.designSettings.titleAlignment,
                        backgroundColor:
                          viz.designSettings.titleBackgroundColor,
                        borderRadius: viz.designSettings.titleBorderRadius,
                        paddingTop: viz.designSettings.paddingTopTitle,
                        paddingBottom: viz.designSettings.paddingBottomTitle,
                        paddingLeft: viz.designSettings.paddingLeftTitle,
                        paddingRight: viz.designSettings.paddingRightTitle,
                      }}
                    >
                      <div
                        className={styles.title}
                        style={{
                          color: viz.designSettings.titleColor,
                          fontSize: viz.designSettings.valueTitleSize,
                        }}
                      >
                        {getTitle()}
                      </div>
                      {filterSubtitle &&
                        viz.designSettings.hasSubtitle &&
                        vizQs.length > 0 && (
                          <div className={styles.subtitle}>
                            {filterSubtitle}
                          </div>
                        )}
                    </div>
                  )}

                  {(!fetchAnswers?.data?.answers ||
                    !fetchAnswers?.data?.answers?.length) && (
                    <div className={styles.noData}>No Data</div>
                  )}

                  {((fetchAnswers?.data?.answers &&
                    fetchAnswers.data.answers.length > 0) ||
                    newChart) && (
                    <>
                      <Chart
                        data={getData()}
                        onSegClick={onSegClick}
                        viz={viz}
                        thumbnail={thumbnail}
                        idAddOn={idAddOn}
                        reDraw={reDraw}
                        update={update}
                        saveData={saveData}
                        inEdit={inEdit}
                      />
                      {viz.designSettings?.showN && vizQs.length > 0 && (
                        <div
                          className={styles.answerCount}
                          style={thumbnail ? { fontSize: ".5em" } : undefined}
                        >
                          {viz.designSettings?.NLabel
                            ? viz.designSettings?.NLabel
                            : "N"}{" "}
                          = {N}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            {showDrill && drill && (
              <Table
                answers={
                  drill.useCompared ? getComparableAnswers() : getAnswers()
                }
                filters={filters}
                onClose={closeDrill}
                viz={viz}
                projects={
                  drill.useCompared
                    ? getComparableProjects.data?.survey
                    : getProjects.data?.survey
                }
                surveyTags={getSurveyTags.data.tags}
                drill={drill}
                titleStyle={getStyle()}
                custom_fields={custom_fields}
                togglespreadsheet
              />
            )}
            {seeData && (
              <Table
                answers={getAnswers()}
                filters={filters}
                onClose={closeSeeData}
                viz={viz}
                projects={getProjects.data?.survey}
                chartData={chartData ? getData() : ""}
                setChartData={setChartData}
                titleStyle={getStyle()}
                custom_fields={custom_fields}
                togglespreadsheet
              />
            )}
          </>
        )}
    </>
  );
}
