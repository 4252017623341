// External
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

// Internal
import styles from "./OrganizationDetails.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import {
  useCreateCustomField,
  useDeleteCustomField,
  useFetchOrganization,
  useGetOrgLogoUnique,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useApplyColorContacts,
  useFetchContactCount,
} from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import { useParams } from "react-router-dom";
import user from "assets/images/blank-profile-picture.png";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { useFetchOrgRolesList } from "api/resources/organization/roles";
import { maintimezones } from "assets/functions/Variables";
import OrgColorPalettes from "./OrgColorPalettes/OrgColorPalettes";
import ImageEditor from "components/Image Editor/ImageEditor";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useSearchDistConfigByOrg } from "api/resources/projects/distributions";
import { OldUserSig } from "../users/user_signatures/OldUserSignature";
import AddressInput from "components/inputs/input_fields/AddressInput/AddressInput";
import StatBox from "components/StatBox/StatBox";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Modal from "components/ReactModal/ReactModal.jsx";

export const OrganizationDetails = ({ role }) => {
  const { id } = useParams();

  const [showAdminSettings, setShowAdminSettings] = useState(false);
  const getOrgLogo = useGetOrgLogoUnique(id, 0);

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(false);

  const MyOrg = useFetchOrganization(id);
  const orgRoles = useFetchOrgRolesList(id);

  //fetch role for org by user id
  const fetchContactCount = useFetchContactCount(id);

  function getType(logoLink) {
    if (logoLink) {
      //do logo link
      return logoLink;
    } else {
      return user;
    }
  }

  function initZone(time) {
    let zone = maintimezones.find((t) => t.value === time);
    if (zone) {
      return zone?.label;
    } else {
      return "Mountain Time, US & Canada";
    }
  }

  const updateOrg = useUpdateOrgSettings();

  function onChange(val, field) {
    let edits = { ...org };
    edits[field] = val;
  }

  return (
    <div className={styles.page}>
      {(MyOrg.isLoading ||
        fetchContactCount.isLoading ||
        getOrgLogo.isLoading ||
        orgRoles.isLoading) && <Loading></Loading>}
      {(MyOrg.isError ||
        fetchContactCount.isError ||
        getOrgLogo.isError ||
        orgRoles.isError) && <div>Error...</div>}
      {MyOrg.isSuccess &&
        fetchContactCount.isSuccess &&
        !showAdminSettings &&
        getOrgLogo.isSuccess &&
        orgRoles.isSuccess && (
          <>
            <Modal
              show={edit}
              onClose={() => setEdit(false)}
              rightStyle
              dark
              modalStyle={{
                width: "500px",
                height: "100%",
                padding: "1rem",
              }}
            >
              <div className={styles.header_3} style={{ fontWeight: "400" }}>
                Edit Organization Information
              </div>
              <FlexRow start>
                <div>
                  <ImageEditor
                    src={getOrgLogo.data?.getLogoLinkForOrg?.imageURL}
                    alt={MyOrg.data?.organization?.name}
                    canEdit
                    isLogo={true}
                    position={
                      getOrgLogo.data?.getLogoLinkForOrg?.position &&
                      typeof getOrgLogo.data?.getLogoLinkForOrg?.position ===
                        "string"
                        ? JSON.parse(
                            getOrgLogo.data?.getLogoLinkForOrg?.position
                          )
                        : getOrgLogo.data?.getLogoLinkForOrg?.position
                    }
                    image={getOrgLogo.data?.getLogoLinkForOrg}
                    refetch={getOrgLogo.refetch}
                    show={show}
                    setShow={setShow}
                    height={150}
                    width={250}
                    orgId={id}
                    noShadow
                  ></ImageEditor>
                </div>
                <div className={styles.link}>
                  {" "}
                  <i className="bi-image"></i>{" "}
                  <a onClick={() => setShow(true)}>Edit Logo</a>
                </div>
              </FlexRow>
              <OrgFields
                role={role}
                organization={MyOrg.data?.organization}
              ></OrgFields>
            </Modal>

            <div className={styles.pageSection}>
              <div className={styles.pageSection2}>
                <div className={styles.section}>
                  <FlexRow>
                    <div
                      className={styles.header_3}
                      style={{ fontWeight: "400" }}
                    >
                      Organization Information
                    </div>
                    <Button
                      shadow
                      style={{ gap: ".5rem" }}
                      onClick={() => setEdit(true)}
                    >
                      <i className="bi-pencil"></i> Edit
                    </Button>
                  </FlexRow>
                  <FlexRow start style={{ marginTop: "-.5rem" }}>
                    <ImageEditor
                      src={getOrgLogo.data?.getLogoLinkForOrg?.imageURL}
                      alt={MyOrg.data?.organization?.name}
                      // canEdit
                      isLogo={true}
                      position={
                        getOrgLogo.data?.getLogoLinkForOrg?.position &&
                        typeof getOrgLogo.data?.getLogoLinkForOrg?.position ===
                          "string"
                          ? JSON.parse(
                              getOrgLogo.data?.getLogoLinkForOrg?.position
                            )
                          : getOrgLogo.data?.getLogoLinkForOrg?.position
                      }
                      image={getOrgLogo.data?.getLogoLinkForOrg}
                      refetch={getOrgLogo.refetch}
                      // show={show}
                      // setShow={setShow}
                      height={100}
                      width={250}
                      orgId={id}
                      noShadow
                    ></ImageEditor>
                    {/* <div className={styles.link}>
                      {" "}
                      <i className="bi-image"></i>{" "}
                      <a onClick={() => setShow(true)}>
                        Edit Logo
                      </a>
                    </div> */}
                  </FlexRow>

                  <div className={styles.infoTable}>
                    <div
                      className={`${styles.infoRow} ${styles.gray} ${styles.top}`}
                    >
                      <span>Organization Name</span>
                      <span>{MyOrg.data?.organization?.name}</span>
                    </div>
                    <div className={`${styles.infoRow} ${styles.dark}`}>
                      <span>Time Zone</span>
                      <span>
                        {initZone(MyOrg.data?.organization?.timezone)}
                      </span>
                    </div>

                    <div className={`${styles.infoRow} ${styles.gray}`}>
                      <span>Website</span>
                      <span>{MyOrg.data?.organization?.subdomain}</span>
                    </div>
                    <div className={`${styles.infoRow} ${styles.dark}`}>
                      <span>Address</span>
                      <span>
                        {MyOrg.data?.organization?.address1
                          ? `${MyOrg.data?.organization?.address1}${
                              MyOrg.data?.organization?.address2
                                ? "," + MyOrg.data?.organization?.address2
                                : ""
                            } ${MyOrg.data?.organization?.city}, ${
                              MyOrg.data?.organization?.state
                            } ${MyOrg.data?.organization?.postalCode} \n ${
                              MyOrg.data?.organization?.country
                            }`
                          : "No Address"}
                      </span>
                    </div>
                    <div
                      className={`${styles.infoRow} ${styles.gray} ${styles.bottom}`}
                    >
                      <span>Account ID</span>
                      <span>{MyOrg.data?.organization?.id}</span>
                    </div>
                  </div>
                </div>

                <FlexRow style={{ flexWrap: "wrap" }} gap={"1rem"}>
                  <StatBox
                    title="Contacts"
                    value={fetchContactCount.data.contactCountByOrgId}
                    description="Number of Contacts"
                  ></StatBox>
                  <StatBox
                    title="Audiences"
                    value={fetchContactCount.data.audienceCountByOrgId}
                    description="Number of Audiences"
                  ></StatBox>
                </FlexRow>
                <FlexRow style={{ flexWrap: "wrap" }} gap={"1rem"}>
                  <StatBox
                    title="Projects"
                    value={fetchContactCount.data.projectCountByOrgId}
                    description="Number of projects"
                    // details="View Details"
                    onClickDetails
                  ></StatBox>
                  <StatBox
                    title="Users"
                    value={fetchContactCount.data.userCountByOrgId}
                    description="Number of Users"
                    // details="View Details"
                    onClickDetails
                  ></StatBox>
                </FlexRow>
              </div>
              <div className={styles.editImage}>
                <FlexRow>
                  <div
                    className={styles.header_3}
                    style={{ fontWeight: "400", margin: "0" }}
                  >
                    Themes and Colors
                  </div>
                </FlexRow>

                {/* <FlexRow style={{ justifyContent: "space-around" }}>
                  <FlexRow start style={{ width: "fit-content" }}>
                    <ColorPicker
                      defaultColor={MyOrg?.data?.organization?.primaryColor}
                    ></ColorPicker>{" "}
                    <span className={styles.header_6} style={{ margin: "0" }}>
                      Primary Color
                    </span>
                  </FlexRow>
                  <FlexRow start style={{ width: "fit-content" }}>
                    <ColorPicker
                      defaultColor={MyOrg?.data?.organization?.secondaryColor}
                    ></ColorPicker>{" "}
                    <span className={styles.header_6} style={{ margin: "0" }}>
                      Secondary Color
                    </span>
                  </FlexRow>
                </FlexRow> */}

                <div className={styles.section_label5} style={{ margin: "0" }}>
                  Colors Palettes
                </div>

                <OrgColorPalettes orgId={id} />
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export function OrgSignatures({ show, setShow }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "createdAt",
    descend: true,
  });
  const [active, setActive] = useState();
  const searchsigs = useSearchDistConfigByOrg(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );
  return (
    <>
      <ReactModal
        show={show && !active}
        onClose={() => setShow(false)}
        modalStyle={{ borderRadius: "1em", padding: "2em", width: "800px" }}
        dark
      >
        <div className={styles.sigs}>
          {searchsigs.isSuccess &&
            searchsigs.data?.distconfigs?.length > 0 &&
            searchsigs.data?.distconfigs?.map((sig, i) => (
              <div key={i}>
                <CombinedInput
                  value={`<${sig?.aliasName}> ${sig?.fromAddress}`}
                  label="Signature"
                  // options={maintimezones}
                  // onChange={(val) => {
                  //   onChange(val, "timezone");
                  //   setTimeZone(val);
                  // }}
                  // select
                  disableInputOnly
                  // inputStyle={styles.inputStyle}
                  inputClass={styles.inputStyle}
                  onInputClick={() => setActive(sig)}
                  icon={"bi-pencil-square"}
                  onIconClick={() => setActive(sig)}
                />
              </div>
            ))}
          <div className={styles.link}>
            {" "}
            {/* <i className="bi-image"></i>{" "} */}
            <a
              onClick={() =>
                setActive({
                  signature: "",
                  aliasName: "",
                  type: "signature",
                  fromAddress: "",
                })
              }
            >
              Create New Signature
            </a>
          </div>
        </div>
      </ReactModal>
      <OldUserSig active={active} setActive={setActive} orgLevel></OldUserSig>
    </>
  );
}

export function APISettings({ organization, role }) {
  const updateOrg = useUpdateOrgSettings();
  const [editApiKey, setEditApiKey] = useState(false);
  const [changeKey, setChangeKey] = useState(false);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  const [org, setOrg] = useState(organization);
  const [edited, setEdited] = useState(false);
  const [newKey, setNewKey] = useState("");

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: org.id,
          data: {
            subdomain: org.subdomain,
            sendGridName: org.sendGridName,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              setEdited(false);
              setBtnMsg("Save Changes");
            }, 750);
          },
        }
      );
    }
  }

  function saveAPIKey() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: org.id,
          data: {
            sendgridApiKey: newKey,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setEditApiKey(false);
            setNewKey(false);
          },
        }
      );
    }
  }

  function handleCancel() {
    setEdited(false);
    setOrg({ ...organization });
  }

  function onChange(val, field) {
    if (!edited) {
      setEdited(true);
    }
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
  }

  return (
    <>
      {/* <div style={{ width: "500px", margin: "auto" }}>
        <TextField
          value={org.subdomain}
          placeholder="Domain Name..."
          onChange={(val) => onChange(val, "subdomain")}
          label="Domain Name"
          disable={!role?.canUpdateOrganization}
        ></TextField>
        <TextField
          value={org.sendGridName}
          placeholder="Sendgrid Name..."
          onChange={(val) => onChange(val, "sendGridName")}
          label="Sendgrid Name"
          disable={!role?.canUpdateOrganization}
        ></TextField>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className={styles.previous} onClick={() => setEditApiKey(true)}>
            Edit Api Key
          </div>
        </div>
      </div> */}
      <CombinedInput
        value={"********"}
        label="Sendgrid API Key"
        // options={maintimezones}
        // onChange={(val) => {
        //   onChange(val, "timezone");
        //   setTimeZone(val);
        // }}
        // select
        disableInputOnly
        icon={"bi-pencil-square"}
        onIconClick={() => setEditApiKey(true)}
        style={{ backgroundColor: "white" }}
        shadow
      />
      <ReactModal
        show={editApiKey}
        onClose={() => setEditApiKey(false)}
        modalStyle={{ borderRadius: "1em", padding: "1em" }}
        dark
      >
        {!changeKey && (
          <TextFieldSimple
            defaultValue={"a0f9dxz9cp93ur09wasud09ae9-9n7"}
            value={"a0f9dxz9cp93ur09wasud09ae9-9n7"}
            password
            disable
            label="Current Sendgrid API Key"
          />
        )}
        {changeKey && (
          <TextFieldSimple
            value={newKey}
            onChange={(val) => setNewKey(val)}
            password
            label="New API Key"
            placeholder="Enter New Key..."
          />
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {!changeKey && (
            <div className={styles.previous} onClick={() => setChangeKey(true)}>
              Change API Key?
            </div>
          )}
          {changeKey && (
            <div style={{ display: "flex", gap: "1em", paddingTop: ".5em" }}>
              <Button
                red
                height={35}
                onClick={() => {
                  setChangeKey(false);
                  setNewKey("");
                }}
              >
                Cancel
              </Button>
              <Button height={35} onClick={saveAPIKey}>
                Save
              </Button>
            </div>
          )}
        </div>
      </ReactModal>
    </>
  );
}
export function EditCustomFields({
  customfields,
  orgId,
  refetch,
  noAddFields,
  noModal,
  user,
}) {
  const [addValue, setAddValue] = useState("");

  const [show, setShow] = useState(false);
  const [chosenField, setChosenField] = useState();
  const [newField, setNewField] = useState(false);

  const fixedFields = ["firstName", "lastName", "email"];
  const createField = useCreateCustomField();
  const deleteField = useDeleteCustomField();
  const fixcolors = useApplyColorContacts();

  function handleEditField(field, index) {
    field.properties =
      typeof field.properties == "string"
        ? JSON.parse(field.properties)
        : field.properties;
    field.index = index;
    setChosenField(field);
    setAddValue("");
    setShow(true);
  }

  function handleClose() {
    setChosenField(null);
    setNewField(false);
    setShow(false);
  }

  function handleDeleteField(field, index) {
    if (
      window.confirm(
        "Are you sure you want to delete this custom field? It will no longer be visible in your chart filters."
      )
    ) {
      deleteField.mutate(
        {
          id: field.id,
        },
        {
          onSuccess: () => {
            // let fields = [...custom_fields];
            // fields.splice(index, 1);
            // setCustomFields(fields);
            setShow(false);
          },
        }
      );
    }
  }

  function handleAddField() {
    // let fields = [...custom_fields];
    let newField = {
      name: addValue,
      displayName: addValue,
      properties: "[]",
      delimiter: "",
      organizationId: orgId,
    };

    createField.mutate(
      {
        data: newField,
      },
      {
        onSuccess: (data) => {
          // newField.id = data.createCustomField.id;
          // fields.push(newField);
          // setCustomFields(fields);
          setAddValue("");
        },
      }
    );
  }

  function handleSaved() {
    if (!noModal) {
      setShow(false);
    }

    if (refetch) {
      refetch();
    }
  }

  useEffect(() => {
    if (noModal && chosenField && show) {
      setShow(false);
    }
  }, [chosenField]);

  useEffect(() => {
    if (!show && chosenField) {
      setShow(true);
    }
  }, [show]);

  function applyColors() {
    fixcolors.mutate();
  }

  return (
    <>
      {show && noModal && (
        <div className={styles.singleFieldBox}>
          <div className={styles.singleField}>
            {" "}
            <EditSingleCustomField
              onClose={handleClose}
              field={chosenField}
              onSaved={handleSaved}
              brandNew={newField}
              noModal={noModal}
              deleteField={handleDeleteField}
            />
          </div>
        </div>
      )}
      {!noAddFields && (
        <div className={styles.link}>
          +{" "}
          <a
            onClick={() => {
              setNewField(true);
              setShow(true);
            }}
          >
            Add Field
          </a>
        </div>
      )}
      <div
        className={styles.customFieldsContainer}
        style={{
          display: noModal ? "flex" : "",
          position: noModal ? "sticky" : "",
          top: noModal ? "0px" : "",
        }}
      >
        {show && !noModal && (
          <EditSingleCustomField
            onClose={handleClose}
            field={chosenField}
            onSaved={handleSaved}
            brandNew={newField}
            noModal={noModal}
            deleteField={handleDeleteField}
          />
        )}
        <div
          className={styles.customFieldsBox}
          style={{ width: noModal ? "300px" : "" }}
        >
          {noModal && <div className={styles.choose}>Choose Field</div>}
          <div
            className={`s ${styles.section_label5}`}
            style={{
              justifyContent: "space-between",
              display: "flex",
              padding: ".5rem .5rem",
              height: "fit-content",
              margin: "0",
            }}
          >
            <span>Name</span>
            <span>Properties</span>
          </div>
          {customfields &&
            customfields.map((field, index) => (
              <div
                className={`${styles.menu_item} ${
                  field.name === chosenField?.name && styles.menu_item_active
                }`}
                key={index}
                onClick={() => handleEditField(field, index)}
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  padding: ".5rem .5rem",
                  height: "fit-content",
                }}
              >
                <span>{field.name}</span>
                <span>{field?.properties?.length}</span>
                {/* <div className={styles.customFieldHolder2}>
                  <div
                    className={`${styles.customField}  ${
                      noModal &&
                      chosenField &&
                      field.name === chosenField?.name &&
                      styles.chosenField
                    }`}
                    onClick={() => handleEditField(field, index)}
                  >
                    {field.name}
                  </div>
                  <div
                    className={styles.edit}
                    onClick={() => handleEditField(field, index)}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </div>
                </div>
                <div
                  className={`${styles.delete} ${
                    noModal &&
                    chosenField &&
                    field.name === chosenField?.name &&
                    styles.chosenField
                  }`}
                  onClick={() => handleDeleteField(field, index)}
                >
                  <i className="bi bi-x"></i>
                </div> */}
              </div>
            ))}
          {user?.isSuperAdmin && (
            <div className={styles.choose}>
              <Button onClick={() => applyColors()} shadow>
                Fix Colors
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function OrgFields({ role, organization }) {
  const [org, setOrg] = useState({ ...organization });
  const [edited, setEdited] = useState(false);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  // const [editTime, setEditTime] = useState(false);
  // const [editWebsite, setEditWebsite] = useState(false);
  // const [editAddress, setEditAddress] = useState(false);
  // const [editName, setEditName] = useState(false);
  const updateOrg = useUpdateOrgSettings();
  const [timeZone, setTimeZone] = useState(initZone());
  const [defaultLocale, setDefaultLocale] = useState();

  function initZone() {
    let zone = maintimezones.find((t) => t.value === organization.timezone);
    if (zone) {
      return zone;
    } else {
      return {
        timezone: "America/Denver",
        value: "America/Denver",
        offset: 25200,
        display: "GMT-07:00",
        label: "Mountain Time, US & Canada",
      };
    }
  }

  // const languages = [
  //   { id: 0, name: "English", value: "en" },
  //   { id: 1, name: "Español", value: "sp" },
  //   { id: 2, name: "Português", value: "pt" },
  // ];

  const defaultLocales = [{ id: 0, name: "US", value: "US" }];

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            name: org.displayName,
            displayName: org.displayName,
            timezone: timeZone ? timeZone.value : undefined,
            defaultLocale: defaultLocale ? defaultLocale.value : undefined,
            subdomain: org.subdomain,
            sendGridName: org.sendGridName,
            address1: org?.address1,
            address2: org?.address2,
            city: org?.city,
            country: org?.country,
            postalCode: org?.postalCode,
            state: org?.state,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  function handleCancel() {
    setOrg({ ...organization });
    // setEditName(false);
    // setEditWebsite(false);
    // setEditTime(false);
    // setEditAddress(false);
  }

  function onChange(val, field) {
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
    setEdited(true);
  }

  return (
    <div className={styles.content} style={{ padding: "0rem", gap: "1rem" }}>
      {/* <div className={styles.settings}> */}
      <TextFieldSimple
        value={org.displayName}
        placeholder="Organization Name..."
        onChange={(val) => onChange(val, "displayName")}
        label="Organization Name"
        shadow
        style={{ backgroundColor: "white", cursor: "normal" }}
      />
      <SelectFieldCustom
        value={timeZone}
        label="Time Zone"
        shadow
        options={maintimezones}
        select
        onChange={(v) => {
          onChange(v.value, "timezone");
          setTimeZone(v);
        }}
        style={{ backgroundColor: "white" }}
      />
      <TextFieldSimple
        value={org.subdomain}
        label="Website"
        shadow
        style={{ backgroundColor: "white" }}
        onChange={(val) => onChange(val, "subdomain")}
      />
      {/* <CombinedInput
        value={
          org?.address1
            ? `${org?.address1}${org?.address2 ? "," + org?.address2 : ""} ${
                org?.city
              }, ${org?.state} ${org?.postalCode} \n ${org?.country}`
            : "No Address"
        }
        label="Address"
        shadow
        style={{ backgroundColor: "white" }}
      /> */}
      <AddressInput onChange={onChange} org={organization}></AddressInput>
      {/* <CombinedInput
        value={org.id}

        // onChange={(val) => onChange(val, "subdomain")}
        label="Account ID"
        // disable
        shadow
        // icon={"bi-pencil"}
        style={{ backgroundColor: "white" }}
      /> */}
      <div
        style={{
          display: "flex",
          gap: "1em",
          paddingTop: ".5em",
          justifyContent: "flex-end",
        }}
      >
        <Button red height={35} onClick={handleCancel} shadow link>
          Cancel
        </Button>
        <Button height={35} onClick={handleSave} shadow blue disable={!edited}>
          {BtnMsg}
        </Button>
      </div>
    </div>
  );
}
